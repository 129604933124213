import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AvatarProfile, Input } from '../../../../../../../../reusable';
import './ZircleUsersList.scss';
import { SearchLens } from '../../../../../../../../../img';
import { UserType } from '../../../../../../../../../types';

interface Props {
  users?: UserType[];
}

const ZircleUsersList: React.FC<Props> = ({ users }) => {
  useEffect(() => {
    const root = document.querySelector('#root') as HTMLDivElement;
    if (root) {
      root.style.overflow = 'hidden';
    }

    return () => {
      root.style.overflow = '';
    };
  }, []);

  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const filteredUsers = users?.filter(user =>
    user.nick.toLowerCase().includes(search.toLowerCase())
  );

  const goToProfile = (email: string) => () => {
    navigate(`/profile?profileId=${email}`);
  };

  return (
    <div className="zircle-users-list__container">
      <Input
        onChange={e => setSearch(e.target.value)}
        placeholder="Buscar usuarios wezircle"
        type="text"
        value={search}
        iconLeft={SearchLens}
      />
      <div className="zircle-users-list__users-list">
        {filteredUsers?.map(user => (
          <div key={user.id} className="zircle-users-list__user-row">
            <div className="clickable" onClick={goToProfile(user.email)}>
              <AvatarProfile height={40} image={user.avatar} width={40} />
            </div>
            <div className="clickable" onClick={goToProfile(user.email)}>
              {user.nick}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { ZircleUsersList };
