import React from 'react';
import { useNavigate } from 'react-router-dom';

import './ProfileInfoCard.scss';
import { AvatarProfile } from '../../../../../../reusable';
import { useScreenType } from '../../../../../../../hooks';
import { UserType } from '../../../../../../../types';
import { Mail, SettingsGear, Telephone } from '../../../../../../../img';

interface ProfileInfoCardProps {
  isOwnProfile?: boolean;
  user?: UserType;
}

const ProfileInfoCard: React.FC<ProfileInfoCardProps> = ({
  isOwnProfile,
  user,
}) => {
  const navigate = useNavigate();
  const screenType = useScreenType();
  const avatarSize = React.useMemo(
    () => (screenType === 'desktop' ? 120 : 72),
    [screenType]
  );

  const goToSettings = () => {
    navigate('/settings');
  };

  return (
    <div className="profile-card__container">
      {isOwnProfile && (
        <img
          className="profile-card__settings clickable"
          onClick={goToSettings}
          src={SettingsGear}
          height={32}
          width={32}
        />
      )}
      <div className="profile-card__avatar">
        <AvatarProfile
          height={avatarSize}
          image={user?.avatar}
          width={avatarSize}
        />
      </div>
      <div className="profile-card__title">{user?.nick}</div>
      <div className="profile-card__item">
        <img src={Mail} height={14} width={14} />
        <div className="profile-card__info">{user?.email}</div>
      </div>
      <div className="profile-card__item">
        <img src={Telephone} height={14} width={14} />
        <div className="profile-card__info">{user?.phone}</div>
      </div>
    </div>
  );
};

export { ProfileInfoCard };
