import React from 'react';
import { AvatarProfile } from '../../image';
import { Button } from '../../forms';
import { PostPurposeType, UserType } from '../../../../types';
import './PostHeader.scss';
import { useNavigate } from 'react-router-dom';
import { Mail, MsTeams, Telephone } from '../../../../img';

const actionDone: Record<PostPurposeType, string> = {
  gift: 'regalado',
  rent: 'alquilado',
  sell: 'vendido',
  share: 'compartido',
};

interface PostHeaderProps {
  isMyPost: boolean;
  postId?: string;
  postType: PostPurposeType;
  redirect?: () => void;
  user: UserType;
}

const PostHeader: React.FC<PostHeaderProps> = ({
  isMyPost,
  postId,
  postType,
  redirect,
  user,
}) => {
  const navigate = useNavigate();
  const goToProfile = (email: string) => () => {
    navigate(`/profile?profileId=${email}`);
  };
  return (
    <div className="post-header__container">
      <div
        className="post-header__user-data clickable"
        onClick={goToProfile(user.email)}
      >
        <AvatarProfile height={40} image={user.avatar} width={40} />

        <div className="post-header__user-info">
          <div className="post-header__user-name">{user.nick}</div>
          {!isMyPost && (
            <>
              <div className="post-header__user-contact">
                <img src={Mail} height={10} width={10} />
                {user.email}
              </div>
              <div className="post-header__user-contact">
                <img src={Telephone} height={10} width={10} />
                {user.phone}
              </div>
            </>
          )}
        </div>
      </div>
      {isMyPost && (
        <Button
          text={`Lo he ${actionDone[postType]}`}
          onClick={() => navigate(`/posts/close/${postId}`)}
          size="sm"
          type="secondary"
        />
      )}
      {!!redirect && (
        <Button onClick={redirect} size="sm">
          <div className="post-header__chat-button">
            <img src={MsTeams} alt="" height={20} width={20} />
            Chat
          </div>
        </Button>
      )}
    </div>
  );
};

export { PostHeader };
