import React, { useState } from 'react';
import './InputComment.scss';
import { Send, SendActive } from '../../../../img';
import { Input, InputProps } from '../Input/Input';

type InputCommentProps = InputProps & {
  onSend: () => void;
};

const InputComment = ({ onSend, ...props }: InputCommentProps) => {
  const [isActive, setIsActive] = useState(false);

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.code === 'Enter') {
      onSend();
    }
  };

  return (
    <div className="input-comment">
      <Input
        {...props}
        iconRight={isActive ? SendActive : Send}
        iconAction={onSend}
        onBlur={toggleActive}
        onFocus={toggleActive}
        onKeyDown={handleKeyPress}
        placeholder="Añade un comentario"
      />
    </div>
  );
};

export { InputComment };
