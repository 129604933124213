import React from 'react';
import './MenuRow.scss';

type MenuRowProps = {
  icon?: string;
  text: string;
  onClick: () => void;
};

const MenuRow = ({ icon, text, onClick }: MenuRowProps) => {
  return (
    <div className="menu-row-container clickable" onClick={onClick}>
      {icon && <img src={icon} alt="" />}
      {text}
    </div>
  );
};

export { MenuRow };
