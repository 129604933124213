import React from 'react';
import './Card.scss';
import { RadioButton } from '../../RadioButton/RadioButton';

type CardProps = {
  selected: boolean;
  title: string;
  link: React.ReactNode;
  illustration: string;
  value: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>, value: string) => void;
};

const Card: React.FC<CardProps> = (props: CardProps) => {
  const getSelectedClass = () => {
    if (props.selected) {
      return ' selected';
    } else {
      return '';
    }
  };

  return (
    <div
      className={'card' + getSelectedClass()}
      onClick={(e: React.MouseEvent<HTMLDivElement>) =>
        props.onClick(e, props.value)
      }
    >
      <div className="card-illustration">
        <img
          className="illustration"
          src={props.illustration}
          alt="illustration"
        />
      </div>
      <div className="text-container">
        <span className="title">{props.title}</span>
        <div className="link">{props.link}</div>
      </div>
      <RadioButton disabled={false} selected={props.selected} label={''} />
    </div>
  );
};

Card.defaultProps = {
  selected: false,
};

export { Card, type CardProps };
