import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getExpiringLocalStorageItem } from '../../../../utils';
import { useScreenType } from '../../../../hooks';
import { DesktopMenu } from './components/desktop-menu/DesktopMenu';
import { MobileMenu } from './components/mobile-menu/MobileMenu';

const Menu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const screenType = useScreenType();
  const [token, setToken] = useState('');
  const activeRoute = useMemo(() => {
    if (location.pathname.includes('new')) {
      return 'new';
    } else if (
      location.pathname.includes('search') ||
      (location.pathname.includes('posts') &&
        !location.pathname.includes('profile'))
    ) {
      return 'search';
    } else if (
      location.pathname.includes('profile') ||
      location.pathname.includes('settings')
    ) {
      return 'profile';
    } else {
      return 'home';
    }
  }, [location.pathname]);

  const goToSection = (sectionRoute: string) => {
    navigate(`/${sectionRoute}`);
  };

  useEffect(() => {
    const userData = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    setToken(userData?.session ?? '');
  }, [location.pathname]);

  return (
    <div className={`menu-container ${!token && 'hidden'}`}>
      {screenType === 'desktop' && (
        <DesktopMenu activeRoute={activeRoute} goToSection={goToSection} />
      )}
      {screenType === 'mobile' && (
        <MobileMenu activeRoute={activeRoute} goToSection={goToSection} />
      )}
    </div>
  );
};

export { Menu };
