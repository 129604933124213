import React from 'react';
import './Link.scss';

type LinkProps = {
  text?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  type?: string;
  disabled?: boolean;
  iconR?: string;
  iconL?: string;
};

const Link: React.FC<LinkProps> = (props: LinkProps) => {
  const getIcon = (type: string) => {
    if (type === 'R' && props.iconR) {
      return (
        <img
          className={`icon link-${props.type}`}
          src={props.iconR}
          alt="R-icon"
        />
      );
    } else if (type === 'L' && props.iconL) {
      return (
        <img
          className={`icon link-${props.type}`}
          src={props.iconL}
          alt="L-icon"
        />
      );
    }
  };

  return (
    <div
      className={`link-container ${!props.disabled && 'clickable'}`}
      onClick={props.onClick}
      aria-disabled={props.disabled}
    >
      {getIcon('L')}
      <label
        aria-disabled={props.disabled}
        className={`label link-${props.type} ${props.disabled && 'disabled'} `}
      >
        {props.text}
      </label>
      {getIcon('R')}
    </div>
  );
};

Link.defaultProps = {
  type: 'primary',
  disabled: false,
};

export { Link, type LinkProps };
