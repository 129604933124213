import React from 'react';
import './RadioButton.scss';

type RadioButtonProps = {
  disabled: boolean;
  selected: boolean;
  label: string;
};

const RadioButton: React.FC<RadioButtonProps> = (props: RadioButtonProps) => {
  const getDisabledClass = () => {
    if (props.disabled) {
      return ' disabled';
    } else {
      return '';
    }
  };

  const getSelectedClass = () => {
    if (props.selected) {
      return ' selected';
    } else {
      return '';
    }
  };

  const getButton = () => {
    if (props.selected) {
      return (
        <div className={'button' + getSelectedClass() + getDisabledClass()}>
          <div className="fill" />
        </div>
      );
    } else {
      return <div className={'button' + getDisabledClass()}></div>;
    }
  };

  const getLabel = () => {
    if (props.label.length > 0) {
      return <label className={getDisabledClass()}>{props.label}</label>;
    }
  };

  return (
    <div className="radio-button">
      {getButton()}
      {getLabel()}
    </div>
  );
};

RadioButton.defaultProps = {
  disabled: false,
  selected: false,
  label: '',
};

export { RadioButton, type RadioButtonProps };
