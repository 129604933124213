import React from 'react';
import './DesktopMenu.scss';
import {
  Home,
  Plus,
  SearchLensBlue,
  SendRightBlue,
  UserRound,
  WezircleBlue,
} from '../../../../../../img';
import { useLocation } from 'react-router-dom';
import { removeExpiringLocalStorageItem } from '../../../../../../utils';
import { Link } from '../../../link/Link';

type DesktopMenuProps = {
  activeRoute: string;
  goToSection: (sectionRoute: string) => void;
};

const DesktopMenu = ({ activeRoute, goToSection }: DesktopMenuProps) => {
  const location = useLocation();
  const logout = () => {
    removeExpiringLocalStorageItem('user_token');
    goToSection('login');
  };

  return (
    <div className="desktop-menu__container">
      <div className="desktop-menu__top">
        <img src={WezircleBlue} alt="wezircle-logo" height={48} width={48} />
        <div className="desktop-menu__title">
          <span>Wezircle</span>
          <div className="desktop-menu__subtitle">Zero Emissions</div>
        </div>
      </div>
      <div className="desktop-menu__sections">
        <div
          onClick={() => goToSection('posts/new')}
          className={`desktop-menu__section-item ${
            activeRoute === 'new' && 'desktop-menu__active'
          }`}
        >
          <img src={Plus} alt="add" height={24} width={24} />
          Añadir publicación
        </div>
        <div
          onClick={() => goToSection('home')}
          className={`desktop-menu__section-item ${
            activeRoute === 'home' && 'desktop-menu__active'
          }`}
        >
          <img src={Home} alt="home" height={24} width={24} />
          Inicio
        </div>
        <div
          onClick={() => goToSection('search')}
          className={`desktop-menu__section-item ${
            activeRoute === 'search' && 'desktop-menu__active'
          }`}
        >
          <img src={SearchLensBlue} alt="search" height={24} width={24} />
          Buscar
        </div>
        <div
          onClick={() => goToSection('profile')}
          className={`desktop-menu__section-item ${
            activeRoute === 'profile' && 'desktop-menu__active'
          }`}
        >
          <img src={UserRound} alt="profile" height={24} width={24} />
          Perfil
        </div>
      </div>
      {location.pathname.includes('/profile') && (
        <div className="desktop-menu__log-out" onClick={logout}>
          <Link onClick={logout} text="Cerrar sesión" />{' '}
          <img src={SendRightBlue} alt="->" height={24} width={24} />
        </div>
      )}
    </div>
  );
};

export { DesktopMenu };
