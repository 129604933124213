import React from 'react';
import './MobileHeader.scss';
import { ArrowLeft, SendRight, WezircleMobileHeader } from '../../../../img';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeExpiringLocalStorageItem } from '../../../../utils';
import { landingUrl } from '../../../../constants';

const MobileHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const logout = () => {
    removeExpiringLocalStorageItem('user_token');
    navigate('/login');
  };

  return (
    <div className="mobile-header__container">
      {(location.pathname.includes('/posts') ||
        location.pathname.includes('/settings')) && (
        <div
          className="mobile-header__navigate-back"
          onClick={() => navigate(-1)}
        >
          <img src={ArrowLeft} alt="<-" />
        </div>
      )}
      <img src={WezircleMobileHeader} alt="Wezircle" />
      {location.pathname.includes('/profile') && (
        <div className="mobile-header__log-out" onClick={logout}>
          <img src={SendRight} alt="->" />
        </div>
      )}
      {location.pathname.includes('/login') && (
        <div
          className="mobile-header__navigate-out"
          onClick={() => window.location.replace(landingUrl)}
        >
          salir
        </div>
      )}
    </div>
  );
};

export { MobileHeader };
