import React, { useMemo } from 'react';

interface Co2LevelsProps {
  co2Level?: number;
}

const Co2Levels = ({ co2Level }: Co2LevelsProps) => {
  const levelChildren = useMemo(() => {
    if (!co2Level) return null;
    return new Array(5).fill(0).map((_, index) => {
      let status = '';
      if (index + 1 < co2Level) status = 'completed';
      if (index + 1 === co2Level) status = 'next';
      return (
        <div
          className={`co2-card__level-item ${status}`}
          key={`co2_level_${index}`}
        />
      );
    });
  }, [co2Level]);
  return <div className="co2-card__level-row">{levelChildren}</div>;
};

export default Co2Levels;
