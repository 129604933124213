import { BASE_URL, httpCode } from '../constants';
import { addThousandSeparators, getExpiringLocalStorageItem } from '../utils';

export async function getCo2Info(email?: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(
      `${BASE_URL}/co2/${email ?? ''}`,
      requestOptions
    );
    const data = await response.json();
    if (response.status === httpCode.success) {
      return {
        co2: addThousandSeparators(data.co2),
        co2ForNextLevel: addThousandSeparators(data.co2ForNextLevel),
        driveKm: addThousandSeparators(data.driveKm),
        flights: addThousandSeparators(data.flights),
        iceSeaM3: addThousandSeparators(data.iceSeaM3),
        level: addThousandSeparators(data.level),
        seaPlasticKg: data.seaPlasticKg,
        treesYear: addThousandSeparators(data.treesYear),
      };
    }
  } catch (error) {
    throw new Error('Error fetching data: ' + error);
  }
}
