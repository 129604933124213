import React, { useMemo } from 'react';
import { PostType, ReactionsType } from '../../../../../types';
import {
  Carousel,
  EmojiPickerMenu,
  PostHeader,
  PostInfo,
  Tag,
} from '../../../../reusable';
import { imgRoute } from '../../../../../constants';
import './PostList.scss';

import { useNavigate } from 'react-router-dom';
import { Comments } from '../../../../reusable/post';
import { Illustration } from '../../../../../img';
import { useScreenType } from '../../../../../hooks';
import { getExpiringLocalStorageItem } from '../../../../../utils';

type PostListProps = {
  filteredPosts: PostType[];
  onEmojiChange: (postId: number, newReactions: ReactionsType) => void;
};

const PostList = ({ filteredPosts, onEmojiChange }: PostListProps) => {
  const userEmail = useMemo(
    () => getExpiringLocalStorageItem<string>('user_email'),
    []
  );

  const screenType = useScreenType();

  const illustrationSize = useMemo(
    () => (screenType === 'desktop' ? 200 : 120),
    [screenType]
  );
  const navigate = useNavigate();
  const redirect = (id: string) => {
    navigate(`/posts/${id}`);
  };

  return (
    <div>
      {filteredPosts.length === 0 && (
        <div className="post-list__empty-message">
          <img
            src={Illustration}
            alt=""
            height={illustrationSize}
            width={illustrationSize}
          />
          <div className="post-list__empty-message-title">
            Aún no hay ninguna publicación
          </div>
          <div className="post-list__empty-message-text">
            Aún no hay ninguna publicación. ¡Anímate y sé el primero en
            publicar! Hazlo desde el botón “Añadir publicación”.
          </div>
        </div>
      )}
      {filteredPosts.map(post => {
        const isMyPost = userEmail === post.user.email;

        return (
          <div key={`post_${post.id}`}>
            <PostHeader
              isMyPost={false}
              postType={post.postType}
              redirect={
                isMyPost
                  ? undefined
                  : () => window.open(post.teamsLink, '_blank')
              }
              user={post.user}
            />
            <div className="desktop-split">
              <div className="post-list__images-row">
                {!!post.images.length && (
                  <Carousel
                    baseRoute={imgRoute}
                    images={post.images}
                    redirect={() => redirect(post.id.toString())}
                    tag={<Tag type={post.postType} />}
                  />
                )}
              </div>
              <div className="post-list__content-row">
                <PostInfo
                  co2={post.co2}
                  description={post.description}
                  id={post.id.toString()}
                  price={post.price}
                  redirect={() => redirect(post.id.toString())}
                  title={post.title}
                  isMyPost={false}
                />
                <EmojiPickerMenu
                  id={post.id.toString()}
                  onEmojiAdded={newReactions =>
                    onEmojiChange(post.id, newReactions)
                  }
                  reactions={post.reactions}
                />
                {post.comments?.length ? (
                  <Comments commentsList={post.comments.slice(0, 3)} />
                ) : (
                  <div
                    className="no-comment-message clickable"
                    onClick={() => redirect(post.id.toString())}
                  >
                    No hay comentarios. ¡Escribe el primero!
                  </div>
                )}
              </div>
            </div>
            <div className="post-list__separator" />
          </div>
        );
      })}
    </div>
  );
};

export { PostList };
