import React, { useRef } from 'react';
import { Bin, Plus } from '../../../../img';
import './AddImage.scss';

type AddImageProps = {
  imageToFill: string;
  onAdd: (image: string) => void;
  onDelete: (image: string) => void;
};

const AddImage = (props: AddImageProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null); // ref for accessing the input element
  const onAddImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]; // get the selected file
    if (file) {
      const reader = new FileReader(); // create a file reader
      reader.onload = () => {
        // when the reader is loaded
        const result = reader.result as string; // get the result as a string
        console.log(result);
        props.onAdd(result);
      };
      reader.readAsDataURL(file);
    }
  };

  const resetValue = () => {
    if (inputFileRef.current != null) {
      inputFileRef.current.value = '';
    }
  };

  const getComponentFilled = () => {
    if (props.imageToFill.length > 0) {
      return (
        <div
          className="add-image filled"
          style={{
            backgroundImage: `url(${props.imageToFill})`,
            backgroundSize: 'cover',
          }}
        >
          <div
            className="delete-image clickable"
            onClick={() => props.onDelete(props.imageToFill)}
          >
            <img src={Bin} />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="add-image clickable"
          onClick={() => inputFileRef.current?.click()}
        >
          <input
            type="file"
            accept=".bmp,.png,.jpg,.jpeg" // only accept these file extensions
            onChange={onAddImage}
            onClick={resetValue}
            ref={inputFileRef}
            style={{ display: 'none' }} // hide the input element
          />
          <div className="text-container">
            <img src={Plus} />
            <span className="title">
              Haz una foto o selecciona de tu galería
            </span>
          </div>
        </div>
      );
    }
  };

  return getComponentFilled();
};

AddImage.defaultProps = {
  imageToFill: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDelete: () => {},
};

export { AddImage, type AddImageProps };
