import React, { useMemo } from 'react';

import './ZircleInfoCard.scss';
import { AdaptiveDialog, AvatarProfile } from '../../../../../../reusable';
import { useScreenType } from '../../../../../../../hooks';
import { ZircleUsersList } from './components';
import { UserType } from '../../../../../../../types';

interface ZircleInfoCardProps {
  postsQuantity?: number;
  avatar?: string;
  name?: string;
  users?: UserType[];
  closedPosts?: number;
}

const ZircleInfoCard: React.FC<ZircleInfoCardProps> = ({
  avatar,
  closedPosts,
  name,
  postsQuantity,
  users,
}) => {
  const screenType = useScreenType();
  const [showUsers, setShowUsers] = React.useState(false);
  const avatarSize = useMemo(
    () => (screenType === 'desktop' ? 120 : 72),
    [screenType]
  );

  const openUsersList = () => {
    if (screenType === 'mobile') window.scrollTo(0, 0);
    setShowUsers(true);
  };

  return (
    <div className="zircle-info-card__container">
      <div className="zircle-info-card__avatar">
        <AvatarProfile height={avatarSize} image={avatar} width={avatarSize} />
      </div>
      <div className="zircle-info-card__title">{name}</div>
      <div className="zircle-info-card__item clickable" onClick={openUsersList}>
        <div className="zircle-info-card__info">{users?.length}</div>
        <div className="zircle-info-card__info-label">Miembros</div>
      </div>
      <div className="zircle-info-card__item">
        <div className="zircle-info-card__info">{postsQuantity}</div>
        <div className="zircle-info-card__info-label">Posts abiertos</div>
      </div>
      <div className="zircle-info-card__item">
        <div className="zircle-info-card__info">{closedPosts}</div>
        <div className="zircle-info-card__info-label">Posts zirculados</div>
      </div>
      {showUsers && (
        <AdaptiveDialog
          onClose={() => setShowUsers(false)}
          title="Usuarios en tu mismo Zircle" //TODO: dynamic zircle
        >
          <ZircleUsersList users={users} />
        </AdaptiveDialog>
      )}
    </div>
  );
};

export { ZircleInfoCard };
