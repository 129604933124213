import React, { useState } from 'react';
import EmojiPicker, { Categories } from 'emoji-picker-react';
import { useOutsideClick } from '../../../../hooks';
import { setPostReaction } from '../../../../services/postService';
import { PillEmoji } from '../..';
import { EmojiPlus } from '../../../../img';
import { ReactionsType } from '../../../../types';
import { getExpiringLocalStorageItem } from '../../../../utils';

type EmojiPickerMenuProps = {
  id?: string;
  onEmojiAdded: (reactions: ReactionsType) => void;
  reactions: ReactionsType;
};

const EmojiPickerMenu = ({
  id,
  onEmojiAdded,
  reactions = {},
}: EmojiPickerMenuProps) => {
  const userEmail = getExpiringLocalStorageItem<string>('user_email');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const emojiPickerRef = useOutsideClick(() => setIsMenuOpen(false));

  const onEmojiClick = async (emoji: string) => {
    if (!id) return;
    const response = await setPostReaction(id, emoji);
    if (response) {
      onEmojiAdded(response.reactions);
    }
    if (isMenuOpen) {
      toggleMenu();
    }
  };

  return (
    <>
      <div ref={emojiPickerRef} className="emoji-picker-row">
        {isMenuOpen && (
          <EmojiPicker
            categories={[
              {
                name: '',
                category: Categories.SMILEYS_PEOPLE,
              },
              {
                name: '',
                category: Categories.ANIMALS_NATURE,
              },
              {
                name: '',
                category: Categories.FOOD_DRINK,
              },
              {
                name: '',
                category: Categories.TRAVEL_PLACES,
              },
              {
                name: '',
                category: Categories.ACTIVITIES,
              },
              {
                name: '',
                category: Categories.OBJECTS,
              },
              {
                name: '',
                category: Categories.SYMBOLS,
              },
              {
                name: '',
                category: Categories.FLAGS,
              },
            ]}
            searchDisabled
            skinTonesDisabled
            previewConfig={{
              showPreview: false,
            }}
            onEmojiClick={emojiData => onEmojiClick(emojiData.emoji)}
          />
        )}
      </div>
      <div className="reactions-row">
        {Object.keys(reactions).map(emoji => {
          return (
            <PillEmoji
              key={`emoji_${emoji}`}
              count={reactions[emoji].length}
              onClick={() => onEmojiClick(emoji)}
              isActive={
                !!reactions[emoji].find(user => user.email === userEmail)
              }
            >
              {emoji}
            </PillEmoji>
          );
        })}
        {Object.keys(reactions).length < 5 && (
          <PillEmoji>
            <img
              className="emoji-icon"
              src={EmojiPlus}
              alt="add"
              onClick={() => toggleMenu()}
            />
          </PillEmoji>
        )}
      </div>
    </>
  );
};

export { EmojiPickerMenu };
