import { BASE_URL, httpCode } from '../constants';
import { getExpiringLocalStorageItem } from '../utils';

type checkUserData = {
  name: string;
  zircle: string;
};

type setAvatarData = {
  avatar: string;
};

type setNickData = {
  nick: string;
};

type setPhoneData = {
  phone: string;
};

export async function checkUser({ name, zircle }: checkUserData) {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const params = new URLSearchParams();
    params.set('u', name);
    params.set('z', zircle);
    const response = await fetch(
      `${BASE_URL}/user/check-username?${params.toString()}`,
      requestOptions
    );
    console.log(response);

    if (response.status === httpCode.success) {
      return true;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

type checkEmailData = {
  email: string;
  zircle: string;
};

export async function checkEmail({ email, zircle }: checkEmailData) {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const params = new URLSearchParams();
    params.set('e', email);
    params.set('z', zircle);
    const response = await fetch(
      `${BASE_URL}/user/check-email?${params.toString()}`,
      requestOptions
    );
    console.log(response);

    if (response.status === httpCode.success) {
      return true;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function sendPasswordCode(email: string) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    };
    const response = await fetch(
      `${BASE_URL}/user/forgot-password`,
      requestOptions
    );
    console.log(response);

    if (response.status === httpCode.success) {
      return true;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function resetPassword(
  code: string,
  email: string,
  password: string
) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        code,
        newPassword: password,
      }),
    };
    const response = await fetch(
      `${BASE_URL}/user/reset-password`,
      requestOptions
    );
    console.log(response);

    if (response.status === httpCode.success) {
      return true;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function getUserInfo() {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${BASE_URL}/user`, requestOptions);
    console.log(response);

    if (response.status === httpCode.success) {
      return await response.json();
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function saveAvatar({ avatar }: setAvatarData) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );

    const parts = avatar.split(';');
    const imageType = parts[0].split(':')[1];
    const image = parts[1].split(',')[1];

    const requestOptions = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        image,
        imageType,
      }),
    };
    const response = await fetch(`${BASE_URL}/user/avatar`, requestOptions);
    console.log(response);

    if (response.status === httpCode.success) {
      return await response.json();
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function saveNick({ nick }: setNickData) {
  try {
    console.log(nick);
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nick,
      }),
    };
    console.log(nick);
    const response = await fetch(`${BASE_URL}/user/nick`, requestOptions);
    console.log(response);

    if (response.status === httpCode.success) {
      return await response.json();
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function savePhone({ phone }: setPhoneData) {
  try {
    console.log(phone);
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone,
      }),
    };
    console.log(phone);
    const response = await fetch(`${BASE_URL}/user/phone`, requestOptions);
    console.log(response);

    if (response.status === httpCode.success) {
      return await response.json();
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function removeUser() {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${BASE_URL}/user`, requestOptions);
    console.log(response);

    if (response.status === httpCode.success) {
      return true;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}
