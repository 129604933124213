import React, { useState } from 'react';
import { ReactComponent as MailSent } from '../../../../../img/mail-sent.svg';
import { Button, Input, Link } from '../../../../reusable';
import '../ForgivenPassword.scss';

type ForgivenPasswordSecondStepProps = {
  email: string;
  onNextStep: (data: { code: string }) => void;
  onPrevStep: () => void;
};

// implement previous step function

function ForgivenPasswordSecondStep({
  email,
  onNextStep,
  onPrevStep,
}: ForgivenPasswordSecondStepProps) {
  const [code, setCode] = useState('');

  const confirm = () => {
    onNextStep({ code });
    // send code with email and wait for verification
  };

  return (
    <div className="forgiven-password__step-container">
      <div className="forgiven-password__icon">
        <MailSent />
      </div>
      <div className="forgiven-password-title">Valida tu email</div>
      <div className="forgiven-password-message">
        Por seguridad, te hemos enviado una clave de 6 dígitos a {email}
      </div>
      <div className="forgiven-password__second-step__mail">
        ¿Te has equivocado?{' '}
        <Link onClick={onPrevStep} text="Modifica tu email" />
      </div>

      <div className="forgiven-password-input">
        <Input
          name="name"
          onChange={e => setCode(e.target.value)}
          title="Código de 6 dígitos"
          type="text"
          value={code}
          placeholder="Código 6 dígitos"
        />
      </div>
      <div className="forgiven-password__bottom-container">
        <Button disabled={!code} onClick={confirm} size="sm" text="Continuar" />
      </div>
    </div>
  );
}

export default ForgivenPasswordSecondStep;
