// postsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { PostType } from '../../types';

interface PostsState {
  posts: PostType[];
  loading: boolean;
  error: string | null;
}

const initialState: PostsState = {
  posts: [],
  loading: false,
  error: null,
};

const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    postsLoading: state => {
      state.loading = true;
      state.error = null;
    },
    postsReceived: (state, action) => {
      state.posts = action.payload;
      state.loading = false;
      state.error = null;
    },
    postsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { postsLoading, postsReceived, postsError } = postsSlice.actions;

export default postsSlice.reducer;
