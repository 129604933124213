import React from 'react';
import { AvatarProfile } from '../../../..';
import './CommentUser.scss';
import { useNavigate } from 'react-router-dom';

type CommentUserProps = {
  avatar: string;
  text: string;
  userId: string;
  username: string;
};

const CommentUser = ({ avatar, text, username, userId }: CommentUserProps) => {
  const navigate = useNavigate();
  const goToProfile = (email: string) => () => {
    navigate(`/profile?profileId=${email}`);
  };
  return (
    <div className="comment-container">
      <div className="clickable" onClick={goToProfile(userId)}>
        <AvatarProfile height={40} width={40} image={avatar} />
      </div>
      <div className="comment-content">
        <div
          className="comment-username clickable"
          onClick={goToProfile(userId)}
        >
          {username}
        </div>
        <div className="comment-text">{text}</div>
      </div>
    </div>
  );
};

export { CommentUser };
