import React, { Fragment, useMemo, useState } from 'react';
import './Co2Card.scss';
import Co2Levels from './components/Co2Levels';
import {
  LeftWhite,
  Level1,
  Level2,
  Level3,
  Level4,
  Level5,
  RightWhite,
} from '../../../../img';
import { Co2Info } from '../../../../types';
import {
  flights,
  ice,
  road,
  trees,
  flightsBig,
  iceBig,
  roadBig,
  treesBig,
} from '../../../../img/co2-illustrations';
import { useScreenType } from '../../../../hooks';

interface Co2CardProps {
  co2Info?: Co2Info;
}

const badgeList = [Level1, Level2, Level3, Level4, Level5];

const Co2Card: React.FC<Co2CardProps> = ({ co2Info }: Co2CardProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const screenType = useScreenType();

  const images = useMemo(
    () =>
      screenType === 'mobile'
        ? [null, road, trees, ice, flights]
        : [null, roadBig, treesBig, iceBig, flightsBig],
    [screenType]
  );
  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const startingText = useMemo(
    () => `${co2Info?.co2}Kg CO₂ que equivalen a:`,
    [co2Info?.co2]
  );

  const contentBySlide = [
    <Fragment key="co2_slide_0">
      <div className="co2-card__level-badge">
        {!!co2Info?.level && (
          <>
            <img
              src={badgeList[+co2Info.level - 1]}
              alt="wezircle"
              height={34}
              width={34}
            />
            <div className="co2-card__level-badge-text">
              Nivel {co2Info.level}
            </div>
          </>
        )}
      </div>
      <div className="co2-card__title">
        <div>{co2Info?.co2} Kg CO₂</div>
        <div className="co2-card__subtitle">Total de C0₂ ahorrado</div>
      </div>
      {co2Info?.level && <Co2Levels co2Level={+co2Info.level} />}
      <div className="co2-card__level-subtitle">
        {co2Info?.co2ForNextLevel} Kg de CO₂ para el próximo nivel
      </div>
    </Fragment>,
    <div className="co2-card__illustration-label light" key="co2_slide_5">
      <div className="co2-card__label-start">{startingText}</div>
      <div className="co2-card__label-main">{co2Info?.driveKm} km</div>
      <div className="co2-card__label-end"> conducidos en coche</div>
    </div>,
    <div className="co2-card__illustration-label light" key="co2_slide_4">
      <div className="co2-card__label-start">
        {startingText} CO₂ absorbido por
      </div>

      <div className="co2-card__label-main">{co2Info?.treesYear} árboles</div>
      <div className="co2-card__label-end">durante un año</div>
    </div>,
    <div className="co2-card__illustration-label dark" key="co2_slide_3">
      <div className="co2-card__label-start">{startingText}</div>
      <div className="co2-card__label-main">salvar {co2Info?.iceSeaM3} m²</div>
      <div className="co2-card__label-end">de hielo polar ártico</div>
    </div>,
    <div className="co2-card__illustration-label dark" key="co2_slide_1">
      <div className="co2-card__label-start">{startingText}</div>
      <div className="co2-card__label-main">{co2Info?.flights} vuelos</div>
      <div className="co2-card__label-end">de larga distancia</div>
    </div>,
  ][currentIndex];

  return (
    <div
      className={`co2-card__container co2-card__level-${co2Info?.level}`}
      style={{ backgroundImage: `url(${images[currentIndex]})` }}
    >
      <button className="prev-button" onClick={prevSlide}>
        <img src={LeftWhite} alt="<" />
      </button>
      {contentBySlide}
      <button className="next-button" onClick={nextSlide}>
        <img src={RightWhite} alt=">" />
      </button>
    </div>
  );
};

export { Co2Card };
