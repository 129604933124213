import React from 'react';
import './AvatarProfile.scss';
import { imgRoute } from '../../../../constants';

interface AvatarProfileProps {
  baseRoute?: string;
  height?: number;
  image?: string;
  width?: number;
}

const AvatarProfile: React.FC<AvatarProfileProps> = ({
  baseRoute = imgRoute,
  height,
  image,
  width,
}) => {
  return (
    <div
      className="avatar-container"
      style={{ height: `${height}px`, width: `${width}px` }}
    >
      {/* TODO: Put nickname initials when no image is provided  */}
      {image ? (
        <img
          alt="AG"
          height={height}
          src={`${baseRoute}${image}`}
          width={width}
        />
      ) : (
        'AG'
      )}
    </div>
  );
};

export { AvatarProfile };
