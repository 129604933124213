import React from 'react';
import { PostPurposeType } from '../../../../../types';
import './NavTab.scss';

interface NavTabProps {
  items: { label: string; value?: PostPurposeType }[];
  active?: number;
  onClick?: (index: number, type?: PostPurposeType) => void;
}

const NavTab: React.FC<NavTabProps> = ({ items, active = 0, onClick }) => {
  return (
    <div className="nav-tab__list">
      {items.map((item, index) => (
        <div
          className={`nav-tab__item ${index === active && 'nav-tab__active'}`}
          key={index}
          onClick={() => onClick && onClick(index, item.value)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

export { NavTab };
