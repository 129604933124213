import React, { useMemo } from 'react';
import { PostPurposeType } from '../../../../types';
import './Tag.scss';

type TagProps = {
  type?: PostPurposeType;
  text?: string;
};

const Tag = ({ type, text }: TagProps) => {
  const labelTag = useMemo(() => {
    switch (type) {
      case 'gift':
        return 'Regalar';
      case 'rent':
        return 'Alquiler';
      case 'sell':
        return 'Vender';
      case 'share':
        return 'Compartir';
      default:
        return text;
    }
  }, [type, text]);

  return <div className={`tag-container ${type}`}>{labelTag}</div>;
};

export { Tag };
