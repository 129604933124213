import React, { useEffect, useState } from 'react';
import './ForgivenPassword.scss';
import { Button } from '../../../reusable';
import ForgivenPasswordThirdStep from './steps/ForgivenPasswordThirdStep';
import ForgivenPasswordSecondStep from './steps/ForgivenPasswordSecondStep';
import ForgivenPasswordFirstStep from './steps/ForgivenPasswordFirstStep';
import { resetPassword } from '../../../../services/userService';
import { useScreenType } from '../../../../hooks';
import { BlueStar, WhiteStar } from '../../../../img';

export interface UserData {
  code: string;
  email: string;
  password: string;
}

enum WizardStep {
  Search,
  SetCode,
  SetPassword,
}

type ForgivenPasswordProps = {
  goingBackToLogin: () => void;
};

function ForgivenPassword({ goingBackToLogin }: ForgivenPasswordProps) {
  const screenType = useScreenType();
  const [currentStep, setCurrentStep] = useState<WizardStep>(WizardStep.Search);
  const [userData, setUserData] = useState<UserData>({
    code: '',
    email: '',
    password: '',
  });
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);

  useEffect(() => {
    if (userData.password.length > 0)
      resetPassword(userData.code, userData.email, userData.password).then(() =>
        setShowConfirmationMessage(true)
      );
  }, [userData.password]);

  const handlePrevStep = () => {
    setCurrentStep(
      currentStep === WizardStep.SetPassword
        ? WizardStep.SetCode
        : WizardStep.Search
    );
  };
  const handleNextStep = (data: Partial<UserData>) => {
    switch (currentStep) {
      case WizardStep.SetCode:
        setUserData(prev => ({
          ...prev,
          code: data.code ?? '',
        }));
        setCurrentStep(WizardStep.SetPassword);
        break;
      case WizardStep.SetPassword:
        setUserData(prev => ({ ...prev, password: data.password ?? '' }));
        //TODO: navigate('/login');
        break;
      case WizardStep.Search:
        setUserData(prev => ({ ...prev, email: data.email ?? '' }));
        setCurrentStep(WizardStep.SetCode);

        break;
    }
  };

  const showResetConfirmationOrWizard = () => {
    if (!showConfirmationMessage) {
      return (
        <div className="forgiven-password-container">
          {currentStep === WizardStep.Search && (
            <ForgivenPasswordFirstStep onNextStep={handleNextStep} />
          )}
          {currentStep === WizardStep.SetCode && (
            <ForgivenPasswordSecondStep
              email={userData.email}
              onNextStep={handleNextStep}
              onPrevStep={handlePrevStep}
            />
          )}
          {currentStep === WizardStep.SetPassword && (
            <ForgivenPasswordThirdStep onNextStep={handleNextStep} />
          )}
        </div>
      );
    } else {
      return (
        <div className="forgiven-password-confirmation-container">
          <img
            src={screenType === 'desktop' ? BlueStar : WhiteStar}
            alt="password-confirmed-icon"
          />
          <div className="forgiven-password-confirmation-message">
            <span className="title">Contraseña cambiada con éxito</span>
            <span className="description">
              ¡Listo! Ya puedes{' '}
              <span className="bold">
                {' '}
                acceder con tu email y tu nueva contraseña.
              </span>
            </span>
          </div>
          <div className="forgiven-password-confirmation-button">
            <Button text="Acceder a tu Zircle" onClick={goingBackToLogin} />
          </div>
        </div>
      );
    }
  };

  return showResetConfirmationOrWizard();
}

export { ForgivenPassword };
