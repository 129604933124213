// store.js
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import postReducer from './features/post/postSlice';

const rootReducer = combineReducers({
  post: postReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
