import React, { useEffect, useState } from 'react';
import { Button, Input } from '../../../../reusable';
import '../ForgivenPassword.scss';
import { validateEmail } from '../../../../../utils';
import { sendPasswordCode } from '../../../../../services/userService';
import { BlueEyeOff } from '../../../../../img';

type ForgivenPasswordFirstStepProps = {
  onNextStep: (data: { email: string }) => void;
};

function ForgivenPasswordFirstStep({
  onNextStep,
}: ForgivenPasswordFirstStepProps) {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmailChecked, setIsEmailChecked] = useState(false);

  const checkEmail = async () => {
    // const checkup = await checkZircle(zircleName);
    // console.log(checkup);
    await setIsEmailChecked(true);
    await setIsEmailValid(validateEmail(email));
  };

  const sendCode = async () => {
    await sendPasswordCode(email);
    onNextStep({ email });
  };

  useEffect(() => {
    if (isEmailChecked) {
      if (!email.length) {
        setErrorMessage('Campo requerido');
      } else if (!isEmailValid) {
        setErrorMessage('Formato incorrecto');
      } else {
        setErrorMessage('');
      }
    }
  }, [isEmailValid, isEmailChecked]);

  return (
    <div className="forgiven-password__step-container">
      <div className="forgiven-password__icon">
        <img src={BlueEyeOff} alt="forgiven-password-icon" />
      </div>
      <div className="forgiven-password-title">He olvidado mi contraseña</div>
      <div className="forgiven-password-message secondary">
        Introduce el email que usas en Wezircle.
      </div>

      <div className="forgiven-password-input">
        <Input
          error={errorMessage.length ? true : false}
          name="email"
          errorMessage={errorMessage.length ? errorMessage : undefined}
          onChange={e => setEmail(e.target.value)}
          onBlur={() => email && checkEmail()}
          title="Introduce el email que usas en Wezircle"
          type="email"
          value={email}
          placeholder="Email principal"
        />
      </div>
      <div className="forgiven-password__bottom-container">
        <Button
          disabled={!isEmailValid}
          onClick={sendCode}
          size="sm"
          text="Continuar"
        />
      </div>
    </div>
  );
}

export default ForgivenPasswordFirstStep;
