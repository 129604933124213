import React, { useMemo, useState } from 'react';
import { MailSent } from '../../../../../img';
import { Button, Input, Link } from '../../../../reusable';
import { confirmZircleUser } from '../../../../../services/zircleService';
import { useScreenType } from '../../../../../hooks';

type JoinZircleThirdStepProps = {
  email: string;
  onNextStep: (data: { code: string }) => void;
  onPrevStep: () => void;
  phone: string;
  username: string;
  zircle: string;
};

function JoinZircleThirdStep({
  email,
  onNextStep,
  onPrevStep,
  phone,
  username,
  zircle,
}: JoinZircleThirdStepProps) {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  // const [isLinkDisabled, setLinkDisabled] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLinkDisabled(false);
  //   }, 10000);

  //   return () => clearTimeout(timer);
  // }, []);

  const screenType = useScreenType();

  const logoSize = useMemo(
    () => (screenType === 'desktop' ? 80 : 52),
    [screenType]
  );

  const confirm = async () => {
    if (error) setError('');
    const confirmUserCall = await confirmZircleUser({
      code,
      email,
      nickName: username,
      phone,
      zircle,
    });
    if (confirmUserCall.success) {
      onNextStep({ code });
    } else {
      setError('El código no es correcto.');
    }
  };

  // const resendCode = () => {
  //   console.log('resend');
  // };

  return (
    <div className="join-zircle__step-container">
      <div className="join-zircle__icon">
        <img src={MailSent} alt="" height={logoSize} width={logoSize} />
      </div>
      <div className="join-zircle-title">Valida tu email</div>
      <div className="join-zircle-message">
        Por seguridad, te hemos enviado una clave de 6 dígitos a{' '}
        <strong>{email}</strong>
      </div>
      <div className="join-zircle__third-step__mail">
        ¿Te has equivocado?{' '}
        <Link onClick={onPrevStep} text="Modifica tu email" />
      </div>

      <div className="join-zircle-input">
        <Input
          errorMessage={error}
          onChange={e => setCode(e.target.value)}
          title="Código de 6 dígitos"
          type="text"
          value={code}
        />
        {/* <Link
          onClick={resendCode}
          disabled={isLinkDisabled}
          text="¿No te llega el email?"
        /> */}
      </div>
      <div className="join-zircle__bottom-container">
        <Button disabled={!code} onClick={confirm} size="sm" text="Continuar" />
      </div>
    </div>
  );
}

export default JoinZircleThirdStep;
