import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getExpiringLocalStorageItem } from '../../../../utils';

type WithAuthCheckProps = {
  children: React.ReactNode;
};

const WithAuthCheck: React.FC<WithAuthCheckProps> = ({ children }) => {
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = React.useState(false);
  useEffect(() => {
    const userData = getExpiringLocalStorageItem('user_token');
    if (!userData) {
      navigate('/login');
    }
    setIsLogged(true);
  }, [navigate]);

  return <>{isLogged && children}</>;
};

export default WithAuthCheck;
