import React, { useEffect, useReducer } from 'react';
import { searchPosts } from '../../../services/postService';
import { PostPurposeType, PostType, ReactionsType } from '../../../types';
import { NavTab } from './components/nav-tab';
import { SearchLensBlue } from '../../../img';
import './Home.scss';
import { PostList } from './components/post-list';
import { useNavigate } from 'react-router-dom';
import { ZircleCards } from './components/zircle-cards';

type State = {
  posts: PostType[];
  filteredPosts: PostType[];
  postIds: string[];
  activeTab?: number;
};

type Action =
  | { type: 'SET_POSTS'; payload: PostType[] }
  | {
      type: 'FILTER_POSTS';
      payload: { type: PostPurposeType | undefined; index: number };
    }
  | {
      type: 'SET_REACTIONS';
      payload: { postId: number; reactions: ReactionsType };
    };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_POSTS':
      return {
        ...state,
        posts: action.payload,
        postIds: action.payload.map(p => p.id.toString()),
        filteredPosts: action.payload,
        activeTab: 0,
      };
    case 'FILTER_POSTS': {
      const { type, index } = action.payload;
      const filteredPosts = type
        ? state.posts.filter(post => post.postType === type)
        : state.posts;
      return {
        ...state,
        filteredPosts,
        activeTab: index,
      };
    }
    case 'SET_REACTIONS': {
      const { postId, reactions } = action.payload;
      const posts = state.posts.map(post =>
        post.id === postId ? { ...post, reactions } : post
      );
      return {
        ...state,
        posts,
        filteredPosts: posts,
      };
    }
    default:
      return state;
  }
};

const tabs: { label: string; value?: PostPurposeType }[] = [
  { label: 'Todo' },
  { label: 'Vender', value: 'sell' },
  { label: 'Alquiler', value: 'rent' },
  { label: 'Compartir', value: 'share' },
  { label: 'Regalar', value: 'gift' },
];

const Home = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, {
    filteredPosts: [],
    posts: [],
    postIds: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const postsResult = await searchPosts();

      dispatch({
        type: 'SET_POSTS',
        payload: postsResult ?? [],
      });
    };

    fetchData().catch(console.error);
  }, []);

  const handleFilter = (index: number, type?: PostPurposeType) => {
    dispatch({ type: 'FILTER_POSTS', payload: { type, index } });
  };

  return (
    <div className="home__container">
      <ZircleCards postsQuantity={state.posts?.length} />

      <div className="home__search-card">
        <div className="home__search-card-label">Últimas Novedades</div>
        <div
          className="home__search-anchor clickable"
          onClick={() => navigate('/search')}
        >
          <img src={SearchLensBlue} alt="wezircle" height={24} width={24} />
          Buscar
        </div>
      </div>
      <NavTab
        active={state.activeTab}
        items={tabs}
        onClick={(index, type) => handleFilter(index, type)}
      />
      <PostList
        filteredPosts={state.filteredPosts}
        onEmojiChange={(postId, newReactions) =>
          dispatch({
            type: 'SET_REACTIONS',
            payload: { postId, reactions: newReactions },
          })
        }
      />
    </div>
  );
};

export { Home };
