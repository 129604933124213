import React, { useEffect } from 'react';

import { getZircleInfo } from '../../../../../services/zircleService';
import { Co2Card } from '../../../../reusable';
import './ProfileCards.scss';
import { getCo2Info } from '../../../../../services/co2Service';
import { Co2Info } from '../../../../../types';
import { ProfileInfoCard } from './components/profile-info-card';

interface ProfileCardsProps {
  isOwnProfile?: boolean;
  profileId?: string;
}

const ProfileCards: React.FC<ProfileCardsProps> = ({
  isOwnProfile,
  profileId,
}) => {
  const [profileInfo, setProfileInfo] = React.useState<any>({});
  const [co2Info, setCo2Info] = React.useState<Co2Info>();

  useEffect(() => {
    const fetchData = async () => {
      const profileResult = await getZircleInfo('estrella'); // TODO: dynamic zircle
      setProfileInfo(
        profileResult.users?.find((user: any) => user.email === profileId)
      );
      const co2Result = await getCo2Info(profileId);
      setCo2Info(co2Result);
    };

    fetchData().catch(console.error);
  }, [profileId]);
  return (
    <div className="profile-cards__container">
      <ProfileInfoCard user={profileInfo} isOwnProfile={isOwnProfile} />
      <Co2Card co2Info={co2Info} />
    </div>
  );
};

export { ProfileCards };
