import React, { useState } from 'react';
import { AddImage, Button, Message } from '../../../../reusable';
import '../AddPost.scss';
import { imgRoute } from '../../../../../constants';

type AddPostThirdStepProps = {
  onNextStep: (data: {
    imagesToAdd: string[];
    imagesToDelete: string[];
  }) => void;
  onPrevStep: () => void;
  images: string[];
  isEdit: boolean;
  loading: boolean;
};

// implement previous step function

const AddPostThirdStep: React.FC<AddPostThirdStepProps> = (
  props: AddPostThirdStepProps
) => {
  const [imagesToAdd, setImagesToAdd] = useState<string[]>([]);
  const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);

  const confirmImages = () => {
    props.onNextStep({
      imagesToAdd,
      imagesToDelete: imagesToDelete.map(i => i.replace(imgRoute, '')),
    });
  };

  const deleteImage = (imageToDelete: string) => {
    if (!imagesToAdd.includes(imageToDelete)) {
      setImagesToDelete(imagesToDelete.concat(imageToDelete));
    }
    setImagesToAdd(imagesToAdd.filter(i => i !== imageToDelete));
  };

  const addNewImage = (image: string) => {
    if (imagesToAdd.includes(image)) return;
    setImagesToAdd(imagesToAdd.concat(image));
    console.log(imagesToAdd);
  };

  const getAddImages = () => {
    const imageViewsEmpty = Array.from(
      {
        length:
          4 -
          props.images.length -
          (imagesToAdd.length - imagesToDelete.length),
      },
      (_, index) => <AddImage key={'empty-' + index} onAdd={addNewImage} />
    );
    return props.images
      .filter(i => !imagesToDelete.includes(i))
      .concat(imagesToAdd)
      .map(i => (
        <AddImage
          key={i}
          imageToFill={i}
          onAdd={addNewImage}
          onDelete={deleteImage}
        />
      ))
      .concat(imageViewsEmpty);
  };

  const getAddImageMessage = () => {
    if (!props.isEdit) {
      return (
        <div className="add-image-container-message">
          <Message
            title="La primera será portada del producto"
            type="msg-info"
            description="La primera imagen que selecciones será la portada principal de tu post"
            closeButton={false}
          />
        </div>
      );
    }
  };

  return (
    <div className="add-post__step-container">
      <div className="add-post-title third-step">Ilustra tu post</div>
      <span className="add-post-subtitle">
        Selecciona hasta 4 imágenes (jpeg, jpg , png o bmp), para causar la
        mejor impresión. ¡Vale más una imagen que mil palabras!
      </span>
      <div className="add-image-container">{getAddImages()}</div>
      {getAddImageMessage()}
      <div className="add-post__bottom-container third-step">
        <Button
          onClick={() => confirmImages()}
          size="lg"
          disabled={props.loading}
          text={props.isEdit ? 'Guardar cambios' : 'Publicar'}
        />
      </div>
    </div>
  );
};

export default AddPostThirdStep;
