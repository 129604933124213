export function setExpiringLocalStorageItem<T>(
  key: string,
  value: T,
  expirationInSeconds: number
): void {
  const currentTime = new Date().getTime();
  const expiryTime = currentTime + expirationInSeconds * 1000;

  localStorage.setItem(key, JSON.stringify(value));
  localStorage.setItem(`${key}_expiry`, expiryTime.toString());
}

export function getExpiringLocalStorageItem<T>(key: string): T | null {
  const currentTime = new Date().getTime();
  const localStorageItem = localStorage.getItem(`${key}_expiry`);
  const expiryTime =
    typeof localStorageItem === 'string' && parseInt(localStorageItem, 10);

  if (expiryTime && currentTime < expiryTime) {
    const item = localStorage.getItem(key) ?? '';
    return JSON.parse(item) as T;
  } else {
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}_expiry`);
    return null;
  }
}

export function removeExpiringLocalStorageItem(key: string): void {
  localStorage.removeItem(key);
  localStorage.removeItem(`${key}_expiry`);
}
