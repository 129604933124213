import React, { useEffect, useMemo, useState } from 'react';
import './Search.scss';
import { Input, PostSearchItem } from '../../reusable';
import { PostType } from '../../../types';
import { searchPosts } from '../../../services/postService';
import { CloseCircle, Illustration, SearchLens } from '../../../img';
import { useScreenType } from '../../../hooks';
import { useSearchParams } from 'react-router-dom';

function Search() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSearch = searchParams.get('search') || '';
  const [search, setSearch] = useState(initialSearch);
  const [posts, setPosts] = useState<PostType[]>([]);
  const screenType = useScreenType();

  const illustrationSize = useMemo(
    () => (screenType === 'desktop' ? 200 : 120),
    [screenType]
  );

  useEffect(() => {
    const fetchData = async () => {
      const result = await searchPosts(search);
      setPosts(result ?? []);
    };

    if (search) {
      fetchData().catch(console.error);
      setSearchParams({ search });
    }
  }, [search, setSearchParams]);

  return (
    <div className="search-container">
      <Input
        iconLeft={SearchLens}
        iconRight={search && CloseCircle}
        iconAction={() => setSearch('')}
        type={'text'}
        onChange={e => setSearch(e.target.value)}
        value={search}
      />
      {posts.length ? (
        <div className="post-list">
          {posts.map(post => (
            <PostSearchItem {...post} key={`post_${post.id}`} />
          ))}
        </div>
      ) : (
        <div className="search-message">
          <img
            src={Illustration}
            alt=""
            height={illustrationSize}
            width={illustrationSize}
          />
          <div className="new-search-message">
            {search
              ? 'No hemos encontrado ningun resultado, realiza una nueva búsqueda para encontrar posts'
              : 'Realiza una búsqueda para encontrar posts'}
          </div>
        </div>
      )}
    </div>
  );
}

export { Search };
