import React, { useState } from 'react';
import './Input.scss';
import { AlertIcon, Eye, EyeOff } from '../../../../img';

type InputProps = {
  charLimitCount?: number;
  error?: boolean;
  errorMessage?: string;
  helper?: string;
  iconRight?: string;
  iconLeft?: string;
  iconAction?: () => void;
  name?: string;
  onBlur?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onFocus?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  placeholder?: string;
  title?: string;
  type?: string;
  value: string | number;
};

const Input: React.FC<InputProps> = (props: InputProps) => {
  const [charCount, setCharCount] = useState(props.value.toString().length);
  const [passVisible, setPassVisible] = useState(false);
  const imageRefL = React.useRef<HTMLImageElement>(null);
  const imageRefR = React.useRef<HTMLImageElement>(null);

  const getType = () => {
    if (props.type !== 'password') {
      return props.type;
    }
    if (props.type === 'password' && passVisible) {
      return 'text';
    }
    if (props.type === 'password' && !passVisible) {
      return 'password';
    }
  };

  const hasErrorMessage = () => {
    return props.errorMessage && props.errorMessage.length > 0;
  };

  const hasHelper = () => {
    return props.helper && props.helper.length > 0;
  };

  const getErrorClass = () => {
    if (props.error || hasErrorMessage()) {
      return ' error';
    } else {
      return '';
    }
  };

  const switchPasswordVisibility = () => {
    setPassVisible(!passVisible);
  };

  const onClickIcon = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    props.iconAction?.();
  };

  const getIconL = () => {
    if (props.iconLeft) {
      return (
        <img
          className="Input-icon-r"
          src={props.iconLeft}
          alt="input-icon"
          ref={imageRefL}
          onMouseDown={onClickIcon}
        />
      );
    }
  };
  const getIconR = () => {
    if (props.type === 'password') {
      return (
        <img
          className="Input-icon-r"
          src={passVisible ? EyeOff : Eye}
          alt="eye-icon"
          onClick={switchPasswordVisibility}
        />
      );
    }
    if (props.iconRight) {
      return (
        <img
          className="Input-icon-r"
          ref={imageRefR}
          src={props.iconRight}
          alt="input-icon"
          onMouseDown={onClickIcon}
        />
      );
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.onChange(e);
    setCharCount(e.target.value.length);
  };

  const getCharCount = () => {
    if (props.charLimitCount && props.charLimitCount > 0) {
      return (
        <span
          className={
            'Input-counter' + (!hasErrorMessage() && !hasHelper())
              ? ' alone'
              : ''
          }
        >
          <span className="Input-actual-count">{charCount}</span>/{' '}
          {props.charLimitCount}
        </span>
      );
    }
  };

  const getHelper = () => {
    if (hasHelper()) {
      return <span className="Input-helper">{props.helper}</span>;
    }
  };

  const getHelperAdditionalRow = () => {
    if (hasHelper()) {
      return (
        <div className="Input-additional-row">
          {getHelper()}
          {getCharCount()}
        </div>
      );
    }
  };

  const getAdditionalInfo = () => {
    if (hasErrorMessage()) {
      return [
        <div className="Input-additional-row" key="error_message">
          <div className="Input-error-feedback">
            <img src={AlertIcon} className="alert" alt="alert-icon" />
            <span className="error-text">{props.errorMessage}</span>
          </div>
          {getCharCount()}
        </div>,
        getHelperAdditionalRow(),
      ];
    } else if (!hasErrorMessage() && hasHelper()) {
      return getHelperAdditionalRow();
    } else if (!hasErrorMessage && !hasHelper()) {
      return getCharCount();
    }
  };

  const onBlurInput = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.stopPropagation();
    props.onBlur?.(e);
  };

  const getInputOrTextArea = () => {
    if (props.type === 'textarea') {
      return (
        <textarea
          className={'Input-field' + getErrorClass()}
          maxLength={props.charLimitCount}
          name={props.name}
          onFocus={props.onFocus}
          onKeyDown={props.onKeyDown}
          onBlur={onBlurInput}
          onChange={handleInputChange}
          placeholder={props.placeholder}
          value={props.value}
        />
      );
    } else {
      return (
        <input
          autoCapitalize="none"
          className={'Input-field' + getErrorClass()}
          name={props.name}
          type={getType()}
          step={getType() === 'number' ? '0,01' : undefined}
          maxLength={props.charLimitCount}
          onKeyDown={props.onKeyDown}
          onFocus={props.onFocus}
          onBlur={onBlurInput}
          onChange={handleInputChange}
          placeholder={props.placeholder}
          value={props.value}
        />
      );
    }
  };

  return (
    <div className="Input-body">
      {props.title && <span className="Input-title">{props.title}</span>}
      <div className={'Input-container' + getErrorClass()}>
        {getIconL()}
        {getInputOrTextArea()}
        {getIconR()}
      </div>
      {getAdditionalInfo()}
    </div>
  );
};

export { Input, type InputProps };
