import React from 'react';
import './AdaptiveSidenav.scss';
import { BackgroundLogoBlue } from '../../../../img';
import { landingUrl } from '../../../../constants';

type AdaptiveSidenavProps = {
  children: React.ReactNode;
  withBackground?: boolean;
};

const AdaptiveSidenav: React.FC<AdaptiveSidenavProps> = ({
  children,
  withBackground,
}: AdaptiveSidenavProps) => {
  return (
    <div className={`${withBackground ? 'adaptive-sidenav__background' : ''}`}>
      {withBackground && (
        <img
          alt=""
          className="adaptive-sidenav__logo clickable"
          src={BackgroundLogoBlue}
          onClick={() => window.location.replace(landingUrl)}
        />
      )}
      <div className="adaptive-sidenav__container">
        <div className="adaptive-sidenav__content">{children}</div>
      </div>
    </div>
  );
};

export { AdaptiveSidenav, type AdaptiveSidenavProps };
