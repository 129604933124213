import React, { useCallback, useMemo, useState } from 'react';
import { AdaptiveDialog, MobileModalMenu, Popover } from '../../../../dialog';
import { useNavigate } from 'react-router-dom';
import { useScreenType } from '../../../../../../hooks';
import { Bin, DotsMenu, Edit } from '../../../../../../img';
import { MenuRow } from '../../../../../views/postDetail/components/menu-row';
import { ConfirmationMenu } from '../../../../../views/postDetail/components/confirmation-menu';
import { deletePost } from '../../../../../../services/postService';

interface ThreeDotMenuProps {
  id?: string;
  isMyPost: boolean;
}

const ThreeDotMenu: React.FC<ThreeDotMenuProps> = ({ id }) => {
  const navigate = useNavigate();
  const screenType = useScreenType();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const onEdit = () => {
    navigate('/posts/edit/' + id);
  };
  const onSecondAction = useCallback(() => {
    if (screenType === 'desktop') return;
    setIsMenuOpen(true);
  }, [screenType]);

  const threeDots = useMemo(
    () => (
      <img
        className="info-icon clickable"
        src={DotsMenu}
        alt="input-icon"
        onClick={onSecondAction}
      />
    ),
    [onSecondAction]
  );

  const options = (
    <>
      <MenuRow
        text="Editar"
        icon={Edit}
        onClick={() => {
          onEdit();
        }}
      />
      <MenuRow
        text="Eliminar"
        icon={Bin}
        onClick={() => {
          setIsMenuOpen(false);
          setIsConfirmOpen(true);
        }}
      />
    </>
  );

  const onDelete = async () => {
    if (id) {
      const response = await deletePost(id);
      console.log(response);
      if (response?.success) {
        navigate('/home');
      }
      setIsConfirmOpen(false);
    }
  };

  const deleteConfirmation = (
    <ConfirmationMenu
      onClose={() => setIsConfirmOpen(false)}
      onConfirm={onDelete}
    />
  );

  return (
    <>
      <div className="menu-actions">
        {screenType === 'desktop' ? (
          <Popover
            trigger={threeDots}
            content={options}
            onClose={() => setIsMenuOpen(false)}
          />
        ) : (
          threeDots
        )}
        <>
          <MobileModalMenu
            isOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          >
            {options}
          </MobileModalMenu>
          {screenType === 'desktop' ? (
            isConfirmOpen && (
              <AdaptiveDialog onClose={() => setIsConfirmOpen(false)}>
                {deleteConfirmation}
              </AdaptiveDialog>
            )
          ) : (
            <MobileModalMenu
              isOpen={isConfirmOpen}
              onClose={() => setIsConfirmOpen(false)}
            >
              {deleteConfirmation}
            </MobileModalMenu>
          )}
        </>
      </div>
    </>
  );
};

export { ThreeDotMenu };
