import { useState, useCallback } from 'react';

interface ApiResponse<T, U> {
  data: T | null;
  isLoading: boolean;
  error: string | null;
  execute: (args: U) => Promise<void>;
}

export default function useApi<T, U>(
  apiCall: (args: U) => Promise<{ success: boolean } & T>
): ApiResponse<{ success: boolean } & T, U> {
  const [data, setData] = useState<({ success: boolean } & T) | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const execute = useCallback(
    async (args: U) => {
      setIsLoading(true);
      setData(null);
      setError(null);
      try {
        const response = await apiCall(args);
        setData(response);
        setError(
          (response as { success?: boolean })?.success !== false
            ? null
            : 'Error'
        );
      } catch (err: any) {
        console.log(err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    },
    [apiCall]
  );

  return { data, isLoading, error, execute };
}
