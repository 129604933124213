import React from 'react';
import './MobileModalMenu.scss';
import { useOutsideClick } from '../../../../hooks';
import { CloseIcon } from '../../../../img';

type MobileModalMenuProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

const MobileModalMenu: React.FC<MobileModalMenuProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  const ref = useOutsideClick(onClose);

  return (
    <div
      className={`mobile-modal-menu__dropdown ${isOpen && 'open'}`}
      ref={ref}
    >
      <div className="mobile-modal-menu__dropdown-content">
        <div className="mobile-modal-menu__top-line"></div>
        <button className="mobile-modal-menu__dropdown-exit" onClick={onClose}>
          <img src={CloseIcon} alt="X" height={16} width={16} />
        </button>
        {children}
      </div>
    </div>
  );
};

export { MobileModalMenu };
