import React from 'react';
import { CommentUser } from './components/comment-user';
import { CommentType } from '../../../../types';

type CommentsProps = {
  commentsList?: CommentType[];
};

const Comments = ({ commentsList }: CommentsProps) => {
  return (
    <div className="post-detail__comments">
      {commentsList?.length ? (
        commentsList.map(item => (
          <CommentUser
            key={`comment_${item.id}`}
            text={item.text}
            username={item.user.nick}
            userId={item.user.id}
            avatar={item.user.avatar}
          />
        ))
      ) : (
        <div className="no-comment-message">
          No hay comentarios. ¡Escribe el primero!
        </div>
      )}
    </div>
  );
};

export { Comments };
