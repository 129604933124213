import React from 'react';
import './MobileMenu.scss';
import { Home, Plus, SearchLensBlue, UserRound } from '../../../../../../img';

type MobileMenuProps = {
  activeRoute: string;
  goToSection: (sectionRoute: string) => void;
};

const MobileMenu = ({ activeRoute, goToSection }: MobileMenuProps) => {
  return (
    <div className="mobile-menu">
      <div
        className={`mobile-menu__section-item ${
          activeRoute === 'new' && 'mobile-menu__active'
        }`}
        onClick={() => goToSection('posts/new')}
      >
        <img src={Plus} alt="add" height={24} width={24} />
        Añadir
      </div>
      <div
        className={`mobile-menu__section-item ${
          activeRoute === 'home' && 'mobile-menu__active'
        }`}
        onClick={() => goToSection('home')}
      >
        <img src={Home} alt="home" height={24} width={24} />
        Inicio
      </div>
      <div
        className={`mobile-menu__section-item ${
          activeRoute === 'search' && 'mobile-menu__active'
        }`}
        onClick={() => goToSection('search')}
      >
        <img src={SearchLensBlue} alt="search" height={24} width={24} />
        <span>Buscar</span>
      </div>
      <div
        className={`mobile-menu__section-item ${
          activeRoute === 'profile' && 'mobile-menu__active'
        }`}
        onClick={() => goToSection('profile')}
      >
        <img src={UserRound} alt="profile" height={24} width={24} />
        Perfil
      </div>
    </div>
  );
};

export { MobileMenu };
