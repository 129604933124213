import { useState, useEffect } from 'react';

const MOBILE_SCREEN_WIDTH = 435;
type ScreenType = 'mobile' | 'desktop';

const useScreenType = (): ScreenType => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth <= MOBILE_SCREEN_WIDTH
  );

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= MOBILE_SCREEN_WIDTH);
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile ? 'mobile' : 'desktop';
};

export default useScreenType;
