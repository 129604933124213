import React from 'react';
import './PillEmoji.scss';

type PillEmojiProps = {
  children: React.ReactNode;
  count?: number;
  isActive?: boolean;
  onClick?: () => void;
};

const PillEmoji = ({ children, count, isActive, onClick }: PillEmojiProps) => {
  return (
    <div
      className={`emoji-container ${isActive && 'pill-emoji__active'}`}
      onClick={onClick}
    >
      {children} {count}
    </div>
  );
};

export { PillEmoji };
