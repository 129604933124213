import React, { useEffect, useState } from 'react';
import { Button, Input, Link } from '../../../reusable';
import './Login.scss';
import {
  LoginData,
  postLogin,
  getLoginType,
} from '../../../../services/apiService';
import { setExpiringLocalStorageItem, validateEmail } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../../hooks';

type LoginProps = {
  clickOnForgivenPasswords: () => void;
  onLoginSuccess: (token: string) => void;
};
const Login: React.FC<LoginProps> = ({
  clickOnForgivenPasswords,
  onLoginSuccess,
}: LoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isExternalLogin, setIsExternalLogin] = useState(true);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [isReadyToLogin, setIsReadyToLogin] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const navigate = useNavigate();

  const {
    execute: submitLogin,
    isLoading,
    data,
    error,
  } = useApi<{ session: string }, LoginData>(postLogin);

  useEffect(() => {
    const prevEmail = localStorage.getItem('user_email');
    if (prevEmail) setEmail(JSON.parse(prevEmail));
  }, []);

  useEffect(() => {
    if (data?.success) {
      setExpiringLocalStorageItem(
        'user_token',
        { session: data.session },
        3540 * 1
      );
      setExpiringLocalStorageItem('user_email', email.toLowerCase(), 3540 * 1);
      onLoginSuccess(data.session);
    } else if (error) {
      console.log(error);
    }
  }, [data?.session, data?.success, email, error, onLoginSuccess]);

  useEffect(() => {
    if (!firstRender) {
      const isValid = validateEmail(email);
      if (isValid) {
        checkTypeOfLogin(email);
      } else {
        setIsReadyToLogin(false);
      }
      setIsEmailValid(isValid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    setFirstRender(false);
  }, [firstRender]);

  const checkTypeOfLogin = async (email: string) => {
    const typeOfLogin = await getLoginType(email);
    if (typeOfLogin.type === 'PASSWORD') {
      setIsExternalLogin(false);
      setIsReadyToLogin(true);
    } else if (typeOfLogin.type === 'URL') {
      setIsExternalLogin(true);
      setRedirectUrl(typeOfLogin.redirectUrl);
      setIsReadyToLogin(true);
    }
  };

  const goToForgivenPassword = () => {
    clickOnForgivenPasswords();
  };

  const goToRegister = () => {
    navigate('/join-zircle');
  };

  const onPressEnterSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      submitLogin({ email, password });
    }
  };

  return (
    <div className="Login-container">
      <span className="Login-title">Login en Zircle</span>
      <div className="Login-credentials">
        <div className="Login-email-container">
          <Input
            errorMessage={!isEmailValid ? 'Error de formato' : ''}
            onChange={e => setEmail(e.target.value)}
            title="Tu email"
            type="email"
            value={email}
          />
        </div>
        <div
          className={
            'Login-password-container' + (isExternalLogin ? ' hidden' : '')
          }
        >
          <Input
            title="Contraseña"
            type="password"
            onChange={e => setPassword(e.target.value)}
            onKeyDown={onPressEnterSubmit}
            value={password}
            errorMessage={error ? 'Credenciales incorrectas' : ''}
          />
        </div>
        <div
          className={
            'Login-forgiven-password-container' +
            (isExternalLogin ? ' hidden' : '')
          }
        >
          <Link
            onClick={goToForgivenPassword}
            text="He olvidado mi contraseña"
          />
        </div>
      </div>
      <div className="bottom-container">
        <div className="Login-continue">
          <Button
            onClick={() =>
              isExternalLogin
                ? window.location.assign(redirectUrl)
                : submitLogin({ email, password })
            }
            disabled={isLoading || !isReadyToLogin}
            text="Continuar"
          />
        </div>
        <div className="Login-register-container">
          <Link onClick={goToRegister} text="No tengo cuenta. Registrarme" />
        </div>
      </div>
    </div>
  );
};

export default Login;
