import { useState, useRef, useLayoutEffect } from 'react';

const useTruncatedText = () => {
  const [isFullTextShown, setIsFullTextShown] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (
      textRef.current &&
      textRef.current.scrollHeight > textRef.current.clientHeight + 10
    ) {
      setIsTruncated(true);
    }
  }, []);

  const toggleFullText = () => {
    setIsFullTextShown(!isFullTextShown);
    setIsTruncated(false);
  };

  return { isFullTextShown, isTruncated, textRef, toggleFullText };
};

export default useTruncatedText;
