import React, { useState } from 'react';
import { Button, Input } from '../../../../reusable';
import '../AddPost.scss';
import { Euro } from '../../../../../img';

type secondStepData = {
  type: string;
  title: string;
  description: string;
  price: number;
};

type AddPostSecondStepProps = {
  initialData: secondStepData;
  onNextStep: (data: secondStepData) => void;
};

const AddPostSecondStep: React.FC<AddPostSecondStepProps> = ({
  initialData,
  onNextStep,
}: AddPostSecondStepProps) => {
  const [title, setTitle] = useState(initialData.title);
  const [description, setDescription] = useState(initialData.description);
  const [price, setPrice] = useState(initialData.price);

  const goToImages = async () => {
    const data = {
      type: initialData.type,
      title,
      description,
      price: Number(price),
    };
    console.log(data);
    onNextStep(data);
  };

  const handlePriceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPrice(parseFloat(e.currentTarget.value));
  };

  const getPriceInput = () => {
    if (initialData.type === 'SELL' || initialData.type === 'RENT') {
      return (
        <div className="add-post-input">
          <Input
            type="number"
            name="price"
            helper="Utiliza “.” para poner decimales. Ej: 12.34"
            placeholder="Ej: 12.34"
            title="Precio"
            iconRight={Euro}
            onChange={handlePriceChange}
            value={price.toString()}
          />
        </div>
      );
    }
  };

  return (
    <div className="add-post__step-container">
      <div className="add-post-title">Detalla tu post</div>
      <div className="add-post-input title">
        <Input
          charLimitCount={50}
          placeholder="Ponle un título en pocas palabras"
          helper="Ej: Bicicleta marca Scott seminueva"
          name="title"
          onChange={e => setTitle(e.target.value)}
          title="Escribe un título descriptivo"
          type="text"
          value={title}
        />
      </div>
      <div className="add-post-input">
        <Input
          charLimitCount={500}
          name="description"
          placeholder="Añade más detalles"
          onChange={e => setDescription(e.target.value)}
          title="Detalla tu post"
          type="textarea"
          value={description}
        />
      </div>
      {getPriceInput()}
      <div className="add-post__bottom-container">
        <Button
          onClick={goToImages}
          disabled={title.length === 0 || description.length === 0}
          size="lg"
          text="Continuar a las fotos"
        />
      </div>
    </div>
  );
};

export default AddPostSecondStep;
