import React from 'react';
import './ValidationStatus.scss';

type ValidationStatusProps = {
  items: React.ReactNode[];
};

const ValidationStatus: React.FC<ValidationStatusProps> = ({
  items,
}: ValidationStatusProps) => {
  return <div className="validation-status-container">{items}</div>;
};

export { ValidationStatus, type ValidationStatusProps };
