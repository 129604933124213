import React, { useEffect, useState } from 'react';
import {
  Button,
  CardList,
  Card,
  Link,
  MobileModalMenu,
  AdaptiveDialog,
} from '../../../../reusable';
import '../AddPost.scss';
import { useScreenType } from '../../../../../hooks';
import { postGift, postRent, postSell, postShare } from '../../../../../img';
// import { checkZircle } from '../../../../../services/zircleService';

type firstStepData = {
  type: string;
};

type AddPostFirstStepProps = {
  initialData: firstStepData;
  onNextStep: (data: { type: string }) => void;
};

type HelpDialogs = {
  [key: string]: { title: string; description: string };
};

const AddPostFirstStep: React.FC<AddPostFirstStepProps> = ({
  initialData,
  onNextStep,
}: AddPostFirstStepProps) => {
  const [type, setType] = useState(initialData.type);
  const [currentHelp, setCurrentHelp] = useState('');
  const screenType = useScreenType();

  useEffect(() => {
    setType(initialData.type);
  }, [initialData.type]);

  const helpDialog: HelpDialogs = {
    SELL: {
      title: '¿Qué implica vender?',
      description:
        'Significa darle una segunda oportunidad a cambio de una cantidad económica que represente su valor actual. Por ejemplo, si ya no utilizas una bicicleta pero no está para tirar al punto limpio, puedes venderla por un valor realista que creas que puede tener ahora. Por ejemplo, 80€. Si por el contrario no quisieras ponerle ningún valor económico, quizá tu opción sea regalarlo.',
    },
    RENT: {
      title: '¿Qué implica alquilar?',
      description:
        '¿Tienes algo que no usas muy a menudo pero de lo que no te quieres deshacer? Puedes hacerlo por día, semana, mes, año. Por ejemplo, si ya no utilizas una bicicleta pero no está para tirar al punto limpio, puedes venderla por un valor realista que creas que puede tener ahora. Por ejemplo, 80€. Si por el contrario no quisieras ponerle ningún valor económico, quizá tu opción sea regalarlo.',
    },
    GIFT: {
      title: '¿Qué implica regalar?',
      description:
        'Es hora de “dejar en herencia” aquello que ya no usas. ¿Qué mejor manera de hacerlo que gratis? Por ejemplo, si ya no utilizas una bicicleta pero no está para tirar al punto limpio, puedes venderla por un valor realista que creas que puede tener ahora. Por ejemplo, 80€. Si por el contrario no quisieras ponerle ningún valor económico, quizá tu opción sea regalarlo.',
    },
    SHARE: {
      title: '¿Qué implica compartir?',
      description:
        '¿Tienes algo que crees que a otra persona le vendría bien de manera puntual? Puede hacerlo gratis o con un precio. Por ejemplo, si ya no utilizas una bicicleta pero no está para tirar al punto limpio, puedes venderla por un valor realista que creas que puede tener ahora. Por ejemplo, 80€. Si por el contrario no quisieras ponerle ningún valor económico, quizá tu opción sea regalarlo.',
    },
  };

  const checkType = async () => {
    // const checkup = await checkZircle(zircleName);
    // console.log(checkup);

    console.log(type);
    onNextStep({ type });
  };

  const getDialogContent = () => {
    if (currentHelp.length > 0) {
      return (
        <div className="helper-post-container">
          <div className="helper-post-title">
            {helpDialog[currentHelp].title}
          </div>
          <div className="helper-post-description">
            {helpDialog[currentHelp].description}
          </div>
          <div className="helper-post-button">
            <Button
              text="Entendido"
              onClick={() => setCurrentHelp('')}
              size="lg"
            />
          </div>
        </div>
      );
    }
  };

  const getDialog = () => {
    if (screenType === 'mobile')
      return (
        <MobileModalMenu
          isOpen={currentHelp.length > 0}
          onClose={() => setCurrentHelp('')}
        >
          {getDialogContent()}
        </MobileModalMenu>
      );
    else if (currentHelp.length > 0) {
      return (
        <AdaptiveDialog onClose={() => setCurrentHelp('')}>
          {getDialogContent()}
        </AdaptiveDialog>
      );
    }
  };

  const getCards = () => {
    return [
      <Card
        key={1}
        selected={type === 'SELL'}
        title={'Vender'}
        illustration={postSell}
        link={
          <Link
            type="secondary"
            text="¿Qué implica vender?"
            onClick={() => setCurrentHelp('SELL')}
          />
        }
        onClick={() => setType('SELL')}
        value={'SELL'}
      />,
      <Card
        key={2}
        selected={type === 'RENT'}
        title={'Poner en alquiler'}
        illustration={postRent}
        link={
          <Link
            type="secondary"
            text="¿Qué implica alquilar?"
            onClick={() => setCurrentHelp('RENT')}
          />
        }
        onClick={() => setType('RENT')}
        value={'RENT'}
      />,
      <Card
        key={3}
        selected={type === 'GIFT'}
        title={'Regalar'}
        illustration={postGift}
        link={
          <Link
            type="secondary"
            text="¿Qué implica regalar?"
            onClick={() => setCurrentHelp('GIFT')}
          />
        }
        onClick={() => setType('GIFT')}
        value={'GIFT'}
      />,
      <Card
        key={4}
        selected={type === 'SHARE'}
        title={'Compartir'}
        illustration={postShare}
        link={
          <Link
            type="secondary"
            text="¿Qué implica compartir?"
            onClick={() => setCurrentHelp('SHARE')}
          />
        }
        onClick={() => setType('SHARE')}
        value={'SHARE'}
      />,
    ];
  };

  return (
    <div className="add-post__step-container">
      {getDialog()}
      <div className="add-post-title">¿Cuál es el objetivo del post?</div>

      <CardList cards={getCards()} />

      <div className="add-post__bottom-container">
        <Button
          disabled={false}
          onClick={checkType}
          size="lg"
          text="Continuar"
        />
      </div>
    </div>
  );
};

export default AddPostFirstStep;
