import React from 'react';
import './SnackBar.scss';
import {
  AlertSnackBar,
  AlertSnackBar2,
  Check,
  CloseIcon,
  Info,
} from '../../../../img';

type SnackBarProps = {
  type?: string;
  title: string;
  link?: React.JSX.Element;
  onClose: () => void;
  isOpen: boolean;
};

const SnackBar = ({ type, title, link, onClose, isOpen }: SnackBarProps) => {
  const getIcon = () => {
    if (type === 'info') {
      return <img src={Info} className={`icon ${type}`} />;
    }
    if (type === 'warning') {
      return <img src={AlertSnackBar2} className={`icon ${type}`} />;
    }
    if (type === 'success') {
      return <img src={Check} className={`icon ${type}`} />;
    }
    if (type === 'error') {
      return <img src={AlertSnackBar} className={`icon ${type}`} />;
    }
  };

  const getLink = () => {
    if (link !== undefined) {
      return <div className="link-container">{link}</div>;
    }
  };

  return (
    <div className={`snackbar-container ${type}` + (!isOpen ? ' hidden' : '')}>
      <div className="text-container">
        {getIcon()}
        <span className={`title ${type}`}>{title}</span>
        <img
          src={CloseIcon}
          className={`icon ${type} clickable`}
          onClick={onClose}
        />
      </div>
      {getLink()}
    </div>
  );
};

SnackBar.defaultProps = {
  type: 'info',
};

export { SnackBar };
