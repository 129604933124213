import React, { useMemo, useState } from 'react';
import { Button, Input } from '../../../../reusable';
import '../JoinZircle.scss';
import { checkZircle } from '../../../../../services/zircleService';
import { useScreenType } from '../../../../../hooks';
import { SearchGlobe } from '../../../../../img';
// import { checkZircle } from '../../../../../services/zircleService';

type JoinZircleFirstStepProps = {
  onNextStep: (data: { zircleName: string }) => void;
};

function JoinZircleFirstStep({ onNextStep }: JoinZircleFirstStepProps) {
  const [zircleName, setZircleName] = useState('');
  const [isZircleValid, setIsZircleValid] = useState(false);
  const [zircleError, setZircleError] = useState('');

  const screenType = useScreenType();

  const logoSize = useMemo(
    () => (screenType === 'desktop' ? 80 : 52),
    [screenType]
  );

  const checkName = async () => {
    const checkup = await checkZircle(zircleName);
    console.log(checkup);

    console.log(zircleName);
    onNextStep({ zircleName });
  };

  const verifyZircle = async () => {
    if (!zircleName.length) return;
    const exists = await checkZircle(zircleName);

    if (exists) {
      setIsZircleValid(true);
      setZircleError('');
    } else {
      setIsZircleValid(false);
      if (!zircleName.length) {
        setZircleError('Campo requerido');
      } else {
        setZircleError(
          'No se ha encontrado ningún Zircle con este nombre. Prueba con otro nombre.'
        );
      }
    }
  };

  const onPressEnterVerify = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      verifyZircle();
    }
  };

  return (
    <div className="join-zircle__step-container">
      <div className="join-zircle__icon">
        <img src={SearchGlobe} alt="" height={logoSize} width={logoSize} />
      </div>
      <div className="join-zircle-title">Busca un Zircle</div>
      <div className="join-zircle-message secondary">
        Si estás aquí, es porque quieres unirte a un Zircle ya existente. Busca
        el nombre de este.
      </div>

      <div className="join-zircle-input">
        <Input
          name="name"
          errorMessage={zircleError.length ? zircleError : undefined}
          onChange={e => setZircleName(e.target.value)}
          onKeyDown={onPressEnterVerify}
          onBlur={verifyZircle}
          title="Nombre del Zircle"
          type="text"
          value={zircleName}
          placeholder="Busca el nombre del Zircle"
        />
      </div>
      <div className="join-zircle__bottom-container">
        <Button
          disabled={!isZircleValid}
          onClick={checkName}
          size="sm"
          text="Continuar"
        />
      </div>
    </div>
  );
}

export default JoinZircleFirstStep;
