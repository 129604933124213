import React, { useMemo, useState } from 'react';
import { GreenLeaf, Info, Share } from '../../../../img';
import { nativeShare } from '../../../../utils';
import { AdaptiveDialog, MobileModalMenu } from '../../dialog';
import { useScreenType } from '../../../../hooks';
import { Co2Details } from '../co2-details';
import './PostInfo.scss';
import useTruncatedText from './hooks/useTruncatedText';
import { ThreeDotMenu } from './components/three-dot-menu';

interface PostInfoProps {
  id?: string;
  co2: string;
  description: string;
  isMyPost: boolean;
  price: string;
  redirect?: () => void;
  title: string;
}

const PostInfo: React.FC<PostInfoProps> = ({
  id,
  co2,
  description,
  isMyPost,
  price,
  redirect,
  title,
}) => {
  const screenType = useScreenType();
  const [isCo2Open, setIsCo2Open] = useState(false);

  const { isFullTextShown, isTruncated, textRef, toggleFullText } =
    useTruncatedText();

  const onShare = () => {
    const url = `${window.location.origin}/posts/${id}`;
    nativeShare(title, url);
  };

  const co2DetailsComponent = useMemo(
    () => <Co2Details onClose={() => setIsCo2Open(false)} />,
    [setIsCo2Open]
  );

  return (
    <div>
      <div className="info-row">
        <div className="info-price">{price}€</div>|
        <div className="info-co2">
          <img className="info-icon" src={GreenLeaf} alt="input-icon" />
          {co2} Kg de CO₂
          <img
            className="info-icon clickable"
            src={Info}
            alt="input-icon"
            onClick={() => setIsCo2Open(true)}
          />
        </div>
        <div className="info-actions">
          <img
            className="info-icon clickable"
            src={Share}
            onClick={onShare}
            alt="input-icon"
          />
          {isMyPost && <ThreeDotMenu id={id} isMyPost={isMyPost} />}
        </div>
      </div>
      <div
        className={`title-row ${!!redirect && 'clickable'}`}
        onClick={redirect}
      >
        {title}
      </div>
      <div>
        <div
          className={
            isFullTextShown
              ? 'post-info__description full-text'
              : 'post-info__description'
          }
          ref={textRef}
        >
          {description}
        </div>
        {isTruncated && (
          <div
            onClick={() => toggleFullText()}
            className="post-info__description see-more clickable"
          >
            ver más
          </div>
        )}
      </div>
      {isCo2Open &&
        (screenType === 'mobile' ? (
          <MobileModalMenu
            isOpen={isCo2Open}
            onClose={() => setIsCo2Open(false)}
          >
            {co2DetailsComponent}
          </MobileModalMenu>
        ) : (
          isCo2Open && (
            <AdaptiveDialog onClose={() => setIsCo2Open(false)}>
              {co2DetailsComponent}
            </AdaptiveDialog>
          )
        ))}
    </div>
  );
};

export { PostInfo };
