import { useState } from 'react';
import { postContactUs } from '../../../services/apiService';

export interface ContactUsFormType {
  name: string;
  email: string;
  message: string;
  phone: string;
}

interface UseContactFormProps {
  onSubmitted: (response: string) => void;
}

export function useContactForm({ onSubmitted }: UseContactFormProps) {
  const [formData, setFormData] = useState<ContactUsFormType>({
    name: '',
    email: '',
    message: '',
    phone: '',
  });

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(prev => !prev);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const res = await postContactUs(formData);
      console.log(res);
      onSubmitted(res);
    } catch (error) {
      console.error('Error submitting contact form:', error);
    }
  };

  const isFormComplete = !!(
    isChecked &&
    formData.name &&
    formData.email &&
    formData.message
  );

  return {
    formData,
    handleCheckboxChange,
    handleInputChange,
    handleSubmit,
    isChecked,
    isFormComplete,
  };
}
