import React, { useEffect, useState } from 'react';
import './Message.scss';
import { ReactComponent as IconInfo } from '../../../../img/icon-symbols-info.svg';
import { ReactComponent as IconClose } from '../../../../img/icon-symbols-close-thick.svg';

type MessageProps = {
  title: string;
  link?: React.ReactNode;
  type?: string;
  description?: string;
  closeButton?: boolean;
  iconInfo?: boolean;
  permanent?: boolean;
};

const Message: React.FC<MessageProps> = (props: MessageProps) => {
  const [visible, setVisible] = useState(true);

  const getInfo = () => {
    if (props.iconInfo) {
      return <IconInfo className={'img ' + props.type} />;
    }
  };

  useEffect(() => {
    if (!props.closeButton && !props.permanent) {
      // Create a timeout that sets the visibility to false after the duration
      const timeout = setTimeout(() => {
        setVisible(false);
      }, 5000);

      // Return a cleanup function that clears the timeout
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [props.closeButton, props.permanent]);

  const removeMessage = () => {
    setVisible(false);
  };

  const getCloseButton = () => {
    if (props.closeButton) {
      return (
        <div className="mb-close-button" onClick={removeMessage}>
          <IconClose className={'img ' + props.type} />
        </div>
      );
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={'msg ' + props.type}>
      {getInfo()}
      <div className="msg-container">
        <span className="title">{props.title}</span>
        <span className="description">{props.description}</span>
        {props.link}
      </div>
      {getCloseButton()}
    </div>
  );
};

Message.defaultProps = {
  type: 'msg-info',
  closeButton: true,
  iconInfo: false,
  permanent: true,
};

export { Message, type MessageProps };
