export const validateEmail = (email: string): boolean => {
  const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  return !!email?.length && emailPattern.test(email);
};

export const validatePassword = (password: string): boolean => {
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

  return !!password.length && passwordPattern.test(password);
};

export const validateUpperAndLower = (password: string): boolean => {
  const passwordPattern = /^[a-zA-Z0-9]+$/;

  return !!password.length && passwordPattern.test(password);
};

export const validate8CharsAlphaNum = (password: string): boolean => {
  const passwordPattern = /^\w{8,}$/;
  return !!password.length && passwordPattern.test(password);
};
