import React, { useState } from 'react';
import { Button, Input } from '../../../../reusable';
import '../ForgivenPassword.scss';
import {
  validate8CharsAlphaNum,
  validateUpperAndLower,
} from '../../../../../utils';
import {
  CloseRedIcon,
  BlueEyeOff,
  Checkmark,
  DotFill,
} from '../../../../../img';

type thirdStepData = {
  password: string;
};

enum PasswordHint {
  EIGHT_CHARS,
  UPPER_LOWER,
}

type ForgivenPasswordThirdStepProps = {
  onNextStep: (data: thirdStepData) => void;
};

function ForgivenPasswordThirdStep({
  onNextStep,
}: ForgivenPasswordThirdStepProps) {
  const [password, setPassword] = useState('');
  const [passwordRepeated, setPasswordRepeated] = useState('');
  const [hasPassword8Char, setHasPassword8Char] = useState(false);
  const [hasPasswordUpperLower, setHasPasswordUpperLower] = useState(false);
  const [isPasswordChecked, setIsPasswordChecked] = useState(false);

  const changePassword = async () => {
    onNextStep({ password });
  };

  const checkPassword = (passwordChanged: string) => {
    setHasPassword8Char(validate8CharsAlphaNum(passwordChanged));
    setHasPasswordUpperLower(validateUpperAndLower(passwordChanged));
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(e.target.value);
    setIsPasswordChecked(true);
    checkPassword(e.target.value);
  };

  const getFeedbackIcon = (hint: PasswordHint) => {
    if (!isPasswordChecked) {
      return DotFill;
    } else if (hint === PasswordHint.EIGHT_CHARS) {
      if (!hasPassword8Char) {
        return CloseRedIcon;
      } else {
        return Checkmark;
      }
    } else if (hint === PasswordHint.UPPER_LOWER) {
      if (!hasPasswordUpperLower) {
        return CloseRedIcon;
      } else {
        return Checkmark;
      }
    }
  };

  const getPasswordTextClass = (hint: PasswordHint) => {
    if (!isPasswordChecked) {
      return 'text';
    } else if (hint === PasswordHint.EIGHT_CHARS) {
      if (!hasPassword8Char) {
        return 'text error';
      } else {
        return 'text success';
      }
    } else if (hint === PasswordHint.UPPER_LOWER) {
      if (!hasPasswordUpperLower) {
        return 'text error';
      } else {
        return 'text success';
      }
    }
  };

  const getPasswordHints = () => {
    return (
      <>
        <div className="Input-additional-row" key="feedback_message_1">
          <div className="Input-feedback">
            <img
              src={getFeedbackIcon(PasswordHint.EIGHT_CHARS)}
              className="icon"
              alt="feedback-icon"
            />
            <span className={getPasswordTextClass(PasswordHint.EIGHT_CHARS)}>
              Debe incluir al menos 8 caracteres alfanuméricos
            </span>
          </div>
        </div>
        <div className="Input-additional-row" key="feedback_message_2">
          <div className="Input-feedback">
            <img
              src={getFeedbackIcon(PasswordHint.UPPER_LOWER)}
              className="icon"
              alt="feedback-icon"
            />
            <span className={getPasswordTextClass(PasswordHint.UPPER_LOWER)}>
              Debe incluir mayúsculas y minúsculas
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="forgiven-password__step-container">
      <div className="forgiven-password__icon">
        <img src={BlueEyeOff} alt="forgiven-password-icon" />
      </div>
      <div className="forgiven-password-title">Crea tu nueva contraseña</div>
      <div className="forgiven-password-message">
        Asegúrate de crear una contraseña que recuerdes y no tengas que apuntar
        en ningún lado, y que cumpla las condiciones.
      </div>

      <div className="forgiven-password-input">
        <Input
          error={
            (!hasPassword8Char || !hasPasswordUpperLower) && isPasswordChecked
          }
          name="password"
          onBlur={e => password && checkPassword(e.target.value)}
          onChange={handlePasswordChange}
          title="Contraseña"
          type="password"
          value={password}
        />
        {getPasswordHints()}
      </div>
      <div className="forgiven-password-input">
        <Input
          error={password !== passwordRepeated}
          errorMessage={
            password !== passwordRepeated
              ? 'Los códigos no coinciden'
              : undefined
          }
          type="password"
          name="repeat-password"
          title="Repite la contraseña"
          onChange={e => setPasswordRepeated(e.target.value)}
          value={passwordRepeated}
        />
      </div>
      <div className="forgiven-password__bottom-container">
        <Button
          onClick={changePassword}
          disabled={
            !hasPassword8Char ||
            !hasPasswordUpperLower ||
            password !== passwordRepeated
          }
          size="sm"
          text="Continuar"
        />
      </div>
    </div>
  );
}

export default ForgivenPasswordThirdStep;
