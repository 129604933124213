export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return '';
  }

  const formattedDate = date.toLocaleDateString('es-ES', options);

  return formattedDate;
};
