import React from 'react';
import './CardList.scss';

type CardListProps = {
  cards: React.ReactNode[];
};

const CardList: React.FC<CardListProps> = (props: CardListProps) => {
  return <div className={'card-list'}>{props.cards}</div>;
};

CardList.defaultProps = {
  cards: [],
};

export { CardList, type CardListProps };
