import React from 'react';
import './ConfirmationMenu.scss';
import { Button, Link } from '../../../../reusable';

type ConfirmationMenuProps = {
  onClose: () => void;
  onConfirm: () => void;
};

const ConfirmationMenu = ({ onClose, onConfirm }: ConfirmationMenuProps) => {
  return (
    <div className="confirmation-menu__container">
      <div className="confirmation-menu__title">
        ¿Estás seguro que quieres eliminar el post?
      </div>
      <div className="confirmation-menu__body">
        Una vez lo elimines, perderás los datos y los comentarios.
      </div>
      <div className="confirmation-menu__buttons-row">
        <Link text="Cancelar" type="secondary" onClick={onClose} />
        <Button
          onClick={onConfirm}
          text="Eliminar"
          size="sm"
          type="secondary"
        />
      </div>
    </div>
  );
};

export { ConfirmationMenu };
