import { BASE_URL, httpCode } from '../constants';
import { PostType } from '../types';
import { getExpiringLocalStorageItem } from '../utils';

export async function searchPosts(query?: string): Promise<PostType[]> {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const params = new URLSearchParams();
    if (query) {
      params.set('q', query);
    }
    const response = await fetch(
      `${BASE_URL}/posts/?${params.toString()}`,
      requestOptions
    );

    if (response.status === httpCode.success) {
      const content = await response.json();
      return content.posts;
    } else if (
      response.status === httpCode.badRequest ||
      response.status === httpCode.notFound
    ) {
      return [];
    }
    return [];
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function deletePost(id: string): Promise<{ success: boolean }> {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${BASE_URL}/posts/${id}`, requestOptions);

    if (response.status === httpCode.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    throw new Error('Error fetching data:' + error);
  }
}

export async function getPostDetail(id: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${BASE_URL}/posts/${id}`, requestOptions);

    if (response.status === httpCode.success) {
      const content = await response.json();
      return content;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function getPostReactions(id: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(
      `${BASE_URL}/posts/${id}/reactions`,
      requestOptions
    );

    if (response.status === httpCode.success) {
      const content = await response.json();
      return content;
    } else if (response.status === httpCode.notFound) {
      return { reactions: {} };
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function setPostReaction(id: string, reaction: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ reaction }),
    };
    const response = await fetch(
      `${BASE_URL}/posts/${id}/reactions`,
      requestOptions
    );
    if (response.status === httpCode.success) {
      return await response.json();
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function getPostComments(id: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(
      `${BASE_URL}/posts/${id}/comments/`,
      requestOptions
    );

    if (response.status === httpCode.success) {
      const content = await response.json();
      return content;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function setPostComment(id: string, text: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text }),
    };
    const response = await fetch(
      `${BASE_URL}/posts/${id}/comments/`,
      requestOptions
    );
    console.log(response);
    if (response.status === httpCode.success) {
      const content = await response.json();
      console.log(content);
      return content;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

const getImagesSplitted = (images: string[]) => {
  console.log('Images before splitting: ' + images);
  return images.map(i => {
    const parts = i.split(';');
    const imageType = parts[0].split(':')[1];
    const image = parts[1].split(',')[1];
    return { imageType, image };
  });
};

export type uploadPostData = {
  type: string;
  title: string;
  description: string;
  price: number;
  imagesToAdd: string[];
};

export async function uploadPost({
  type,
  title,
  description,
  price,
  imagesToAdd,
}: uploadPostData) {
  try {
    const imagesToAddJson = getImagesSplitted(imagesToAdd);
    console.log(imagesToAddJson);
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type,
        title,
        description,
        price,
        images: imagesToAddJson,
      }),
    };
    const response = await fetch(`${BASE_URL}/posts/`, requestOptions);
    const data = await response.json();
    if (response.status === httpCode.success) {
      return { success: true, ...data };
    } else {
      return { success: false, ...data };
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export type editPostData = {
  id: number;
  type: string;
  title: string;
  description: string;
  price: number;
  imagesToAdd: string[];
  imagesToDelete: string[];
};
export async function editPost({
  id,
  type,
  title,
  description,
  price,
  imagesToAdd,
  imagesToDelete,
}: editPostData) {
  try {
    const imagesToAddJson = getImagesSplitted(imagesToAdd);
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type,
        title,
        description,
        price,
        images: imagesToAddJson,
        imagesToRemove: imagesToDelete,
      }),
    };
    const response = await fetch(`${BASE_URL}/posts/${id}`, requestOptions);
    const data = await response.json();
    if (response.status === httpCode.success) {
      return { success: true, ...data };
    } else if (response.status === httpCode.badRequest) {
      return { success: false, ...data };
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function setPostAsClosed(id: number, tom: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tom,
      }),
    };
    const response = await fetch(
      `${BASE_URL}/posts/${id}/close`,
      requestOptions
    );
    if (response.status === httpCode.success) {
      return true;
    } else if (response.status === httpCode.badRequest) {
      return false;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}
