import React, { useEffect, useRef, useState } from 'react';
import './Settings.scss';
import {
  getUserInfo,
  removeUser,
  saveAvatar,
  saveNick,
  savePhone,
} from '../../../../services/userService';
import {
  AdaptiveDialog,
  AvatarProfile,
  Button,
  Input,
  LineSeparator,
  Link,
  MobileModalMenu,
} from '../../../reusable';
import { Bin, Checkmark, Edit, LeftGreen } from '../../../../img';
import { useScreenType } from '../../../../hooks';
import { useNavigate } from 'react-router-dom';
import { SnackBar } from '../../../reusable/info/snackbar/SnackBar';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const screenType = useScreenType();
  const inputFileRef = useRef<HTMLInputElement>(null); // ref for accessing the input element
  const [nick, setNick] = useState('');
  const [newNick, setNewNick] = useState('');
  const [phone, setPhone] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [email, setEmail] = useState('');
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [isEditingNick, setIsEditingNick] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isSendingPasswordRequest, setIsSendingPasswordRequest] =
    useState(false);

  useEffect(() => {
    getUserInfo()
      .then(u => {
        setNick(u.nick);
        setNewNick(u.nick);
        setPhone(u.phone);
        setNewPhone(u.phone);
        setAvatar(u.avatar);
        setEmail(u.email);
      })
      .catch(e => {
        console.error(e);
      });
  }, []);

  const editAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]; // get the selected file
    if (file) {
      const reader = new FileReader(); // create a file reader
      reader.onload = () => {
        // when the reader is loaded
        const result = reader.result as string; // get the result as a string
        console.log(result);
        saveAvatar({
          avatar: result,
        }).then(avatarSaved => {
          console.log(avatarSaved.avatar);
          setAvatar(avatarSaved.avatar);
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const editNick = () => {
    console.log(newNick);
    saveNick({ nick: newNick })
      .then(nickSaved => {
        console.log(nickSaved);
        console.log(nickSaved.nick);
        setNick(nickSaved.nick);
        setNewNick(nickSaved.nick);
        setIsEditingNick(false);
      })
      .catch(e => console.error(e));
  };

  const editPhone = () => {
    console.log(newPhone);
    savePhone({ phone: newPhone })
      .then(nickSaved => {
        console.log(nickSaved);
        console.log(nickSaved.phone);
        setPhone(nickSaved.phone);
        setNewPhone(nickSaved.phone);
        setIsEditingPhone(false);
      })
      .catch(e => console.error(e));
  };

  const editNickOnKeyPress = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      editNick();
    }
  };

  const editPhoneOnKeyPress = (e: React.KeyboardEvent) => {
    if (e.code === 'Enter') {
      editPhone();
    }
  };

  const cancelNickEdition = () => {
    setNewNick(nick);
    setIsEditingNick(false);
  };

  const cancelPhoneEdition = () => {
    setNewPhone(phone);
    setIsEditingPhone(false);
  };

  const getNickOrEdit = () => {
    if (isEditingNick) {
      return (
        <div className="data-container nick">
          <Input
            title="Tu nombre del usuario"
            charLimitCount={30}
            helper="Debe ser único en tu Zircle y sin espacios."
            iconRight={Checkmark}
            value={newNick}
            onChange={e => setNewNick(e.target.value)}
            iconAction={editNick}
            onBlur={cancelNickEdition}
            onKeyDown={editNickOnKeyPress}
            type="text"
          />
        </div>
      );
    } else {
      return (
        <div className="data-container nick">
          <span className="title">Nombre del usuario</span>
          <span className="nick">{nick}</span>
          <Link
            iconL={Edit}
            text="Editar nombre de usuario"
            onClick={() => setIsEditingNick(true)}
          />
        </div>
      );
    }
  };

  const getPhoneOrEdit = () => {
    if (isEditingPhone) {
      return (
        <div className="data-container nick">
          <Input
            title="Tu número de teléfono"
            charLimitCount={20}
            iconRight={Checkmark}
            value={newPhone}
            onChange={e => setNewPhone(e.target.value)}
            iconAction={editPhone}
            onBlur={cancelPhoneEdition}
            onKeyDown={editPhoneOnKeyPress}
            type="text"
          />
        </div>
      );
    } else {
      return (
        <div className="data-container nick">
          <span className="title">Número de teléfono</span>
          <span className="nick">{phone}</span>
          <Link
            iconL={Edit}
            text="Editar número de teléfono"
            onClick={() => setIsEditingPhone(true)}
          />
        </div>
      );
    }
  };

  const removeAccount = async () => {
    const removed = await removeUser();
    if (removed) {
      navigate(`/`);
    }
  };

  const getDialogContent = () => {
    return (
      <div className="remove-account-message-container">
        <div className="text-container">
          <span className="title">¿Seguro que quieres eliminar tu cuenta?</span>
          <span className="description">
            Una vez la elimines, perderás tu acceso a tu cuenta, así como toda
            la información que has ido construyendo. También perderás la opción
            de seguir causando un gran impacto positivo al planeta.
          </span>
        </div>
        <div className="options-container">
          <Link
            type="secondary"
            text="Cancelar"
            onClick={() => setOpenRemoveDialog(false)}
          />
          <div>
            <Button
              text="Eliminar"
              icon={Bin}
              type="error"
              onClick={removeAccount}
            />
          </div>
        </div>
      </div>
    );
  };

  const getRemoveDialog = () => {
    if (screenType === 'mobile')
      return (
        <MobileModalMenu
          isOpen={openRemoveDialog}
          onClose={() => setOpenRemoveDialog(false)}
        >
          {getDialogContent()}
        </MobileModalMenu>
      );
    else if (openRemoveDialog) {
      return <AdaptiveDialog>{getDialogContent()}</AdaptiveDialog>;
    }
  };

  const getPasswordChangeConfirmation = () => {
    return (
      <SnackBar
        type="success"
        isOpen={isSendingPasswordRequest}
        title={
          'Te acabamos de enviar un email con las instrucciones para cambiar tu contraseña. Revisa tu bandeja de entrada.'
        }
        onClose={() => setIsSendingPasswordRequest(false)}
      />
    );
  };

  return (
    <div className="settings-container">
      {getRemoveDialog()}
      <div
        className="settings__navigate-back clickable"
        onClick={() => navigate('/profile')}
      >
        <img src={LeftGreen} alt="<" />
        Volver a tu perfil
      </div>
      <span className="title">Edita tu perfil</span>
      <div className="avatar-settings-container">
        <AvatarProfile width={120} height={120} image={avatar} />
        <Link
          text="Editar foto de perfil"
          iconL={Edit}
          type="secondary"
          onClick={() => inputFileRef.current?.click()}
        />
        <input
          type="file"
          accept=".bmp,.png,.jpg,.jpeg" // only accept these file extensions
          onChange={editAvatar}
          ref={inputFileRef}
          style={{ display: 'none' }} // hide the input element
        />
      </div>
      {getNickOrEdit()}
      <div className="line-separator-container">
        <LineSeparator />
      </div>
      {getPhoneOrEdit()}
      <div className="line-separator-container">
        <LineSeparator />
      </div>
      <div className="data-container email">
        <span className="title">Email</span>
        <span className="email">{email}</span>
      </div>
      <div className="line-separator-container">
        <LineSeparator />
      </div>
      <div className="data-container password">
        <span className="title">Contraseña</span>
        <span className="password">
          Si quieres <span className="bold">{' cambiar la contraseña, '}</span>
          te enviaremos un email a tu cuenta con un link para configurar tu
          contraseña.
        </span>
        <Link
          text="Cambiar contraseña"
          iconL={Edit}
          onClick={() => setIsSendingPasswordRequest(true)}
        />
      </div>
      <div className="line-separator-container">
        <LineSeparator />
      </div>
      <div className="remove-account-container">
        <Link
          text="Eliminar cuenta"
          type="error"
          iconL={Bin}
          onClick={() => setOpenRemoveDialog(true)}
        />
        {getPasswordChangeConfirmation()}
      </div>
    </div>
  );
};

export { Settings };
