export const nativeShare = (title: string, url: string) => {
  console.log('nativeShare', title, url);
  if (navigator.share) {
    navigator
      .share({
        title,
        // text: 'Check out this link!',
        url,
      })
      .then(() => {
        console.log('Share successful');
      })
      .catch(error => {
        console.error('Share failed:', error);
      });
  } else {
    console.log('Share failed');
  }
};
