import React from 'react';
import './LineSeparator.scss';

type LineSeparatorProps = {
  type?: string;
};

const LineSeparator: React.FC<LineSeparatorProps> = ({ type }) => {
  return <div className={'line-separator ' + type}></div>;
};

LineSeparator.defaultProps = {
  type: 'light',
};

export { LineSeparator };
