export const BASE_URL = 'https://backend.wezircle.com';

export const imgRoute = 'https://wezircle-posts.s3.eu-west-3.amazonaws.com/';

export const landingUrl = 'https://www.wezircle.com';

export const httpCode = {
  success: 200,
  badRequest: 400,
  forbidden: 403,
  notFound: 404,
};
