import React, { useEffect, useState } from 'react';
import './AddPost.scss';
import AddPostFirstStep from './steps/AddPostFirstStep';
import AddPostThirdStep from './steps/AddPostThirdStep';
import AddPostSecondStep from './steps/AddPostSecondStep';
import { useNavigate, useParams } from 'react-router-dom';
import {
  editPost,
  getPostDetail,
  uploadPost,
} from '../../../../services/postService';
import { imgRoute } from '../../../../constants';
import { useApi, useScreenType } from '../../../../hooks';
import { Link } from '../../../reusable';
import { LeftGreen } from '../../../../img';

export interface PostData {
  type: string;
  title: string;
  description: string;
  price: number;
  imagesToAdd: string[];
  imagesToDelete: string[];
  images: string[];
}

enum WizardStep {
  Type,
  Description,
  Images,
}

const AddPost = () => {
  const [currentStep, setCurrentStep] = useState<WizardStep>(WizardStep.Type);
  const [postData, setPostData] = useState<PostData>({
    type: 'SELL',
    title: '',
    description: '',
    price: 0,
    imagesToAdd: [],
    imagesToDelete: [],
    images: [],
  });

  const params = useParams();
  const navigate = useNavigate();
  const screenType = useScreenType();

  const {
    execute: submitUpload,
    isLoading: isUploadLoading,
    data: uploadData,
    error: uploadError,
  } = useApi(uploadPost);

  const {
    execute: submitEdit,
    isLoading: isEditLoading,
    data: editData,
    error: editError,
  } = useApi(editPost);

  // Check if the object is empty or not
  const hasId = Object.keys(params).includes('id');

  useEffect(() => {
    if (uploadData?.success) {
      navigate(`/posts/${uploadData.id}`);
    } else if (uploadError) {
      console.log(uploadError);
    }
  }, [navigate, uploadData, uploadError]);

  useEffect(() => {
    if (editData?.success) {
      navigate(`/posts/${editData.id}?edited=true`);
    } else if (editError) {
      console.log(editError);
    }
  }, [navigate, editData, editError]);

  useEffect(() => {
    if (hasId) {
      const id = params.id ?? '';
      getPostDetail(id)
        .then(postDetails => {
          setPostData({
            title: postDetails.title,
            description: postDetails.description,
            price: postDetails.price,
            type: postDetails.postType.toUpperCase(),
            images: postDetails.images,
            imagesToAdd: [],
            imagesToDelete: [],
          });
        })
        .catch(e => {
          console.error(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrevStep = () => {
    setCurrentStep(
      currentStep === WizardStep.Description
        ? WizardStep.Type
        : WizardStep.Description
    );
    window.scrollTo(0, 0);
  };

  const handleNextStep = async (data: Partial<PostData>) => {
    switch (currentStep) {
      case WizardStep.Type:
        setPostData(prev => ({
          ...prev,
          type: data.type ?? '',
        }));
        console.log(postData);
        setCurrentStep(WizardStep.Description);
        window.scrollTo(0, 0);
        break;
      case WizardStep.Description:
        setPostData(prev => ({
          ...prev,
          title: data.title ?? '',
          description: data.description ?? '',
          price: data.price ?? 0,
        }));
        console.log(postData);
        setCurrentStep(WizardStep.Images);
        window.scrollTo(0, 0);
        break;
      case WizardStep.Images:
        if (!hasId) {
          submitUpload({
            type: postData.type,
            title: postData.title,
            description: postData.description,
            price: postData.price,
            imagesToAdd: data.imagesToAdd ?? [],
          });
        } else {
          const id = params.id ?? '';
          submitEdit({
            id: Number(id),
            type: postData.type,
            title: postData.title,
            description: postData.description,
            price: postData.price,
            imagesToAdd: data.imagesToAdd ?? [],
            imagesToDelete: data.imagesToDelete ?? [],
          });
        }
        break;
    }
  };

  const returnGoBack = () => {
    if (currentStep !== WizardStep.Type) {
      return (
        <Link
          type="secondary"
          text="Paso anterior"
          iconL={LeftGreen}
          onClick={handlePrevStep}
        />
      );
    } else {
      return '';
    }
  };

  const returnNavigator = () => {
    if (screenType === 'desktop') {
      return (
        <div className="add-post-navigator">
          <Link type="secondary" text="Salir" onClick={() => navigate('/')} />
          {returnGoBack()}
        </div>
      );
    }
  };

  return (
    <div className="add-post-page">
      {returnNavigator()}
      <div className="add-post-container">
        {currentStep === WizardStep.Type && (
          <AddPostFirstStep
            initialData={{
              type: postData.type,
            }}
            onNextStep={handleNextStep}
          />
        )}
        {currentStep === WizardStep.Description && (
          <AddPostSecondStep
            initialData={{
              type: postData.type,
              title: postData.title,
              description: postData.description,
              price: postData.price,
            }}
            onNextStep={handleNextStep}
          />
        )}
        {currentStep === WizardStep.Images && (
          <AddPostThirdStep
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
            images={postData.images.map(i => imgRoute + i)}
            isEdit={hasId}
            loading={isUploadLoading || isEditLoading}
          />
        )}
      </div>
    </div>
  );
};

export { AddPost };
