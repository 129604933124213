import React from 'react';
import './PostSearchItem.scss';
import { useNavigate } from 'react-router-dom';
import { GreenLeaf } from '../../../../img';
import { Tag } from '../..';
import { PostPurposeType } from '../../../../types';
import { imgRoute } from '../../../../constants';

type SearchItemProps = {
  co2: string;
  id: number;
  images: string[];
  postType: PostPurposeType;
  price: string;
  title: string;
  fromProfile?: boolean;
};

function PostSearchItem({
  co2,
  id,
  images,
  postType,
  price,
  title,
  fromProfile,
}: SearchItemProps) {
  const navigate = useNavigate();
  const redirect = () => {
    if (fromProfile) {
      navigate(`/profile/posts/${id}`);
    } else {
      navigate(`/posts/${id}`);
    }
  };
  return (
    <div className="post-search-item__container clickable" onClick={redirect}>
      <div className="post-search-item__tag">
        <Tag type={postType} text="ok" />
      </div>
      <img
        alt={title}
        className="post-search-item__image"
        src={`${imgRoute}${images[0]}`}
      />
      <div className="post-search-item__info">
        <div className="post-search-item__price">
          {price}€
          {/* <img className="save-icon" src={Bookmark} alt="save-icon" /> */}
          <div className="post-search-item__carbon-footprint">
            <img className="leaf-icon" src={GreenLeaf} alt="leaf-icon" />
            {co2} Kg de CO2
          </div>
        </div>
        <div className="post-search-item__title">{title}</div>
      </div>
    </div>
  );
}

export { PostSearchItem };
