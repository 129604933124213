import React, { useState, useRef } from 'react';
import './Carousel.scss';
import { LeftGreen, RightGreen } from '../../../../img';

interface CarouselProps {
  baseRoute?: string;
  images: string[];
  redirect?: () => void;
  tag?: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({
  baseRoute = '',
  images,
  redirect,
  tag,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isSingleImage = useRef<boolean>(false);
  isSingleImage.current = images.length <= 1;

  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const jumpToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel">
      {!isSingleImage.current && (
        <button className="prev-button" onClick={prevSlide}>
          <img src={LeftGreen} alt="<" />
        </button>
      )}
      <div className="carousel-tag">{tag}</div>
      <img
        onClick={redirect}
        src={`${baseRoute}${images[currentIndex]}`}
        alt={`Slide ${currentIndex}`}
      />
      {!isSingleImage.current && (
        <button className="next-button" onClick={nextSlide}>
          <img src={RightGreen} alt=">" />
        </button>
      )}

      <div className="page-control">
        {images.map((_, index) => (
          <span
            key={index}
            className={index === currentIndex ? 'dot active' : 'dot'}
            onClick={() => jumpToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export { Carousel };
