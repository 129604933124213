import React, { useCallback, useEffect, useState } from 'react';
import { Login } from '..';
import './Landing.scss';
import { AdaptiveSidenav } from '../../reusable';
import {
  getExpiringLocalStorageItem,
  setExpiringLocalStorageItem,
} from '../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ForgivenPassword } from '../auth/forgiven-pass/ForgivenPassword';
import { getTokenFromCode } from '../../../services/apiService';

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showForgivenPassword, setShowForgivenPassword] = useState(false);
  const code = searchParams.get('code');
  const userLoggedIn = useCallback(() => {
    navigate('/home');
  }, [navigate]);

  useEffect(() => {
    const userData = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    if (userData) {
      userLoggedIn();
    }
  }, [userLoggedIn]);

  useEffect(() => {
    if (code !== null) {
      getTokenFromCode(code)
        .then(result => {
          setExpiringLocalStorageItem(
            'user_token',
            { session: result.id_token },
            3540 * 1
          );
          setExpiringLocalStorageItem(
            'user_email',
            result.email.toLowerCase(),
            3540 * 1
          );
          userLoggedIn();
        })
        .catch(e => console.error(e));
    }
  }, [code, userLoggedIn]);

  return code ? (
    <React.Fragment></React.Fragment>
  ) : (
    <AdaptiveSidenav withBackground>
      {!showForgivenPassword ? (
        <Login
          clickOnForgivenPasswords={() => {
            setShowForgivenPassword(true);
          }}
          onLoginSuccess={userLoggedIn}
        />
      ) : (
        <ForgivenPassword
          goingBackToLogin={() => setShowForgivenPassword(false)}
        />
      )}
    </AdaptiveSidenav>
  );
};

export default Landing;
