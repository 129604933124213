import { BASE_URL, httpCode } from '../constants';
import { getExpiringLocalStorageItem } from '../utils';

export async function getZircleInfo(zircle: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${BASE_URL}/${zircle}`, requestOptions);
    const data = await response.json();
    if (response.status === httpCode.success) {
      return data;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function checkZircle(name: string) {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const params = new URLSearchParams();
    params.set('z', name);
    const response = await fetch(
      `${BASE_URL}/check-zircle?${params.toString()}`,
      requestOptions
    );
    if (response.status === httpCode.success) {
      return true;
    }
    return false;
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

type addUserData = {
  email: string;
  nickName: string;
  password: string;
  zircle: string;
};

export async function addUserToZircle({
  email,
  nickName,
  password,
  zircle,
}: addUserData) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, nickName, password }),
    };

    const response = await fetch(
      `${BASE_URL}/${zircle}/add-user`,
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    // TODO: need 200 status code
    // TODO: control 400 status to not let the wizard go forward
    if (data.message) {
      return data;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

type confirmUserData = {
  code: string;
  email: string;
  nickName: string;
  phone: string;
  zircle: string;
};

// Gives 500 with wrong code
export async function confirmZircleUser({
  code,
  email,
  nickName,
  phone,
  zircle,
}: confirmUserData) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ code, email, phone, nickName }),
    };

    const response = await fetch(
      `${BASE_URL}/${zircle}/confirm-add-user`,
      requestOptions
    );
    const data = await response.json();
    console.log(data);
    // TODO: need 200 status code
    if (response.status === httpCode.success) {
      return { success: true };
    }
    if (response.status === httpCode.badRequest) {
      return { ...data, success: false };
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export async function searchUsers(query?: string) {
  try {
    const token = getExpiringLocalStorageItem<{ session: string }>(
      'user_token'
    );
    const requestOptions = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token?.session}`,
        'Content-Type': 'application/json',
      },
    };
    const params = new URLSearchParams();
    if (query) {
      params.set('q', query);
    }
    const response = await fetch(
      `${BASE_URL}/users?${params.toString()}`,
      requestOptions
    );

    if (response.status === httpCode.success) {
      const content = await response.json();
      return content.users;
    } else if (
      response.status === httpCode.badRequest ||
      response.status === httpCode.notFound
    ) {
      return [];
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}
