import React from 'react';
import './AdaptiveDialog.scss';
import { CloseIcon } from '../../../../img';

type AdaptiveDialogProps = {
  children: React.ReactNode;
  onClose?: () => void;
  title?: string;
};

const AdaptiveDialog = ({ children, onClose, title }: AdaptiveDialogProps) => {
  return (
    <div className="adaptive-dialog__container">
      <div className="adaptive-dialog__content">
        <div className="adaptive-dialog__header">
          <div className="adaptive-dialog__title">{title}</div>
          {onClose && (
            <div className="adaptive-dialog__close" onClick={onClose}>
              <img src={CloseIcon} alt="X" height={24} width={24} />
            </div>
          )}
        </div>
        <div className="adaptive-dialog__body">{children}</div>
      </div>
    </div>
  );
};

export { AdaptiveDialog, type AdaptiveDialogProps };
