import React, { useState } from 'react';
import './Popover.scss';
import { useOutsideClick, useScreenType } from '../../../../hooks';

interface PopoverProps {
  content: React.ReactNode;
  trigger: React.ReactNode;
  onClose: () => void;
}

const Popover: React.FC<PopoverProps> = ({ trigger, content, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const screenType = useScreenType();
  const ref = useOutsideClick(() => {
    onClose();
    setIsVisible(false);
  });

  const togglePopover = () => {
    if (screenType === 'mobile') return;
    setIsVisible(!isVisible);
  };

  return (
    <div className="popover-container">
      <div onClick={togglePopover}>{trigger}</div>
      {isVisible && (
        <div className="popover-content" ref={ref}>
          {content}
        </div>
      )}
    </div>
  );
};

export { Popover };
