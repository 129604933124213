import React, { useEffect } from 'react';

import { getZircleInfo } from '../../../../../services/zircleService';
import { ZircleInfoCard } from './components/zircle-info-card';

import './ZircleCards.scss';
import { getCo2Info } from '../../../../../services/co2Service';
import { Co2Info } from '../../../../../types';
import { Co2Card } from '../../../../reusable';

interface ZircleCardsProps {
  postsQuantity?: number;
}

const ZircleCards: React.FC<ZircleCardsProps> = ({ postsQuantity }) => {
  const [zircleInfo, setZircleInfo] = React.useState<any>({});
  const [co2Info, setCo2Info] = React.useState<Co2Info>();

  useEffect(() => {
    const fetchData = async () => {
      const zircleResult = await getZircleInfo('estrella');
      const co2Result = await getCo2Info();
      setZircleInfo(zircleResult);
      setCo2Info(co2Result);
    };

    fetchData().catch(console.error);
  }, []);
  return (
    <div className="zircle-cards__container">
      <ZircleInfoCard
        avatar={zircleInfo.avatar}
        closedPosts={zircleInfo.closedPosts}
        name={zircleInfo.displayName}
        postsQuantity={postsQuantity}
        users={zircleInfo.users}
      />
      <Co2Card co2Info={co2Info} />
    </div>
  );
};

export { ZircleCards };
