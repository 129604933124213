import React from 'react';
import './Co2Details.scss';
import { Button } from '../..';

type Co2DetailsProps = {
  onClose: () => void;
};

const Co2Details = ({ onClose }: Co2DetailsProps) => {
  return (
    <div className="co2-details__container">
      <div className="co2-details__title">CO2 Ahorrado</div>
      <div className="co2-details__body">
        Estimamos la huella de carbono que se evita producir al reutilizar un
        producto, midiendo las emisiones directas e indirectas producidas a lo
        largo de su ciclo de vida, cuantificando el uso de materia y energía
        desde el proceso de extracción de materiales y fabricación hasta la
        distribución del producto al usuario final.
      </div>
      <Button onClick={onClose} text="Entendido" size="sm" />
    </div>
  );
};

export { Co2Details };
